import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Select,
  useToast,
  useDisclosure,
  VStack,
  Text,
  MenuItem,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { MdBugReport } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { usePostFeedbackMutation } from '../../../../store/store';


interface FeedbackFormData {
  type: 'bug' | 'feedback' | '';
  description: string;
}

const FeedbackButton: React.FC = () => {

  const [postFeedback, { isLoading }] = usePostFeedbackMutation();


  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [formData, setFormData] = useState<FeedbackFormData>({
    type: '',
    description: '',
  });

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const textColor = useColorModeValue('navy.700', 'white');


  useEffect(() => {
    const checkUserLoggedIn = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setIsUserLoggedIn(!!user);
      } catch (error) {
        console.error('Error checking user login status:', error);
        setIsUserLoggedIn(false);
      }
    };

    const handleAuthStateChange = (authData: any) => {
      const { payload: { event } } = authData;
      if (event === 'signIn' || event === 'signOut') {
        checkUserLoggedIn();
      }
    };

    checkUserLoggedIn();
    const listenerCancelToken = Hub.listen('auth', handleAuthStateChange);

    return () => {
      listenerCancelToken();
    };
  }, []);

  const handleOpen = () => {
    if (!isUserLoggedIn) {
      toast({
        title: 'Login Required',
        description: 'Please login to submit feedback or report bugs.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      // Trigger your login modal or redirect to login page
      return;
    }
    onOpen();
  };

  const handleSubmit = async () => {
    if (!formData.type || !formData.description.trim()) {
      toast({
        title: 'Missing Information',
        description: 'Please fill in all fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Replace with your actual API call
      const result = await postFeedback(formData).unwrap();

      toast({
        title: 'Submission Successful',
        description: 'Thank you for your feedback!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          color: 'white',
        },
      });

      // Reset form and close modal
      setFormData({ type: '', description: '' });
      onClose();
    } catch (error) {
      toast({
        title: 'Submission Failed',
        description: 'There was an error submitting your feedback. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          color: 'white',
        },
      });
    } 
  };


  return (
    <>
      {/* Menu Item for Settings Menu */}
      <MenuItem
        onClick={handleOpen}
        _hover={{ bg: 'none' }}
        _focus={{ bg: 'none' }}
        color={textColor}
        borderRadius="8px"
        px="14px"
      >
        <Text fontWeight="500" fontSize="sm" >
          <Icon as={MdBugReport} boxSize={6} me='12px' />
          Report Bug
        </Text>
      </MenuItem>

      {/* Feedback Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={useColorModeValue('white', 'navy.800')} borderRadius="8px">
          <ModalHeader>Submit Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  placeholder="Select type"
                  value={formData.type}
                  onChange={(e) => setFormData({
                    ...formData,
                    type: e.target.value as 'bug' | 'feedback'
                  })}
                >
                  <option value="bug">Bug Report</option>
                  <option value="feedback">Feedback</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Please describe the bug or provide your feedback..."
                  value={formData.description}
                  onChange={(e) => setFormData({
                    ...formData,
                    description: e.target.value
                  })}
                  rows={5}
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={isLoading}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FeedbackButton;
