import beebot from '../images/beebot2.jpg'
import { Box } from '@chakra-ui/react';
import BeeBotForCardAppImage from '../images/BeeBot_app_cover_image_black_and_white.png'


export const navItems = [
  {
    label:
      <>
        BeeBot
        <Box
          as="span"
          fontSize="0.7em"
          position="relative"
          top="-0.5em"
          ml="1px"
          color={'#8b5cf6'}   // text-violet-500 from https://tailwindcss.com/docs/customizing-colors
          fontWeight={'bold'}  // font-semibold from https://tailwindcss.com/docs/font-weight
        >
          AI Chatbot
        </Box>
      </>,
    href: "/beebot",
    photo: beebot,
    imageSrc: BeeBotForCardAppImage, // Replace with your image URLs
    title: <>
      BeeBot
      <Box
        as="span"
        fontSize="0.7em"
        position="relative"
        top="-0.5em"
        ml="1px"
        color={'#8b5cf6'}   // text-violet-500 from https://tailwindcss.com/docs/customizing-colors
        fontWeight={'bold'}  // font-semibold from https://tailwindcss.com/docs/font-weight
      >
        AI Chatbot
      </Box>
    </>,
    description: 'Versatile AI powered chatbot powered by leading AI models inc. OpenAI ChatGPT, Google Gemini, and Anthropic Claude',
  },
  // { label: "Tests", href: "/tests" },
  // { label: "Home", href: "/home" },
  // { label: "Flashcards", href: "/flashcards" },
];