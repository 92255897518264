import ReactMarkdown, { Components as ReactMarkdownComponents } from 'react-markdown'
import { ButtonGroup, IconButton, useColorModeValue } from '@chakra-ui/react'
import Card from '../card/Card'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkLintListItemIndent from 'remark-lint-list-item-indent';
import CodeBlock from '../../../CodeBlock';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css'; // Import the KaTeX CSS
import { MdCheck, MdContentCopy, MdPause, MdPlayArrow } from 'react-icons/md';
import { useRef, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { ENDPOINTS, Message } from '../../types/types';

interface CodeProps extends React.HTMLProps<HTMLDivElement> {
  node?: any; // Define according to the actual structure of the node
  inline?: boolean;
  className?: string;
  children: React.ReactNode; // This allows any valid React nodes to be passed as children
}

interface InlineMathProps {
  value: string; // Assuming the math value will be a string
}

interface BlockMathProps {
  value: string; // Similarly assuming for block math
}
const components: ReactMarkdownComponents = {
  // @ts-ignore
  code: ({ node, inline, className, children, ...props }: CodeProps) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <CodeBlock language={match[1]} code={children} >
        <SyntaxHighlighter
          style={coldarkDark  as any}
          language={match[1]}
          PreTag="pre"
          children={String(children).replace(/\n$/, '')}
          customStyle={{ fontSize: 'medium', width: '100%', overflowX: 'auto', textDecoration: 'none' }} // Increase font size here
          {...props as React.ComponentPropsWithoutRef<typeof SyntaxHighlighter>}
        />
      </CodeBlock>
    ) : (
      // <code className={className} {...props}>
      //   {children}
      // </code>
      <span className='border border-gray-400 rounded mx-1 px-2 py-0.5 font-mono'>
        {children}
      </span>
    );
  },
  // Renderer for inline math
  inlineMath: ({ value }: InlineMathProps) => {
    console.log('inLineMath:', value)
    return (
      <InlineMath math={value} />
    )
  },
  // Renderer for block math
  math: ({ value }: BlockMathProps) => {
    // console.log('BlockMath:', value)
    return (
      <BlockMath math={value} />
    );
  },
};

function convertMathEquation(input: string) {
  // Use a regular expression to find and replace block math equations
  const blockMath = input.replace(/\\\[([\s\S]*?)\\\]/g, (match, p1) => {
    return `$$${p1.trim()}$$`;
  });

  // Use a regular expression to find and replace inline math equations
  return blockMath.replace(/\\\(([\s\S]*?)\\\)/g, (match, p1) => {
    return `$${p1.trim()}$`;
  });
}

interface Props {
  message: Message
  bg?: string
}


export default function MessageBox(props: Props) {
  const { message } = props

  // Math formula conversion
  const output = message.content;
  const convertedOutput = convertMathEquation(output)
  // color scheme
  // const textColor = useColorModeValue('navy.700', 'white')
  const fontColor = useColorModeValue('text-black', 'text-white');

  // TTS functionality
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Controller to abort stream
  const [controller, setController] = useState<AbortController | null>(
    null
  );

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(output);
      setIsCopied(true);
      // Reset the copied state after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };


  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    const handleEnded = () => {
      setIsPlaying(false);
      // audioRef.current?.stop();
    }
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  const fetchTTS = async (text: string) => {
    // authorization jwt toke
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();

    // Create a controller that can abort the entire request.
    const newController = new AbortController();
    const signal = newController.signal;
    setController(newController);

    // body of the fetch request
    const body = {
      inputCode: text,
      endpoint: ENDPOINTS.OPENAI_TTS,
      model: 'tts-1',
      // Turn off ability to add own API Key
      // apiKey,
    };

    // -------------- Fetch --------------
    const urlLambdaFunction = process.env.REACT_APP_BEEBOT_URLLAMBDAFUNCTION || '';

    const response = await fetch(urlLambdaFunction, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': idToken, // Add the JWT token to the Authorization header
      },
      signal,
      body: JSON.stringify(body),
    });

    if (response.ok) {
      // const data = await response.json();
      // return data.audioUrl;

      // const audio = new Audio();
      // audio.src = window.URL.createObjectURL(await response.blob());
      // audio.play();

      return response;

    } else {
      const data = await response.json();
      console.error('Error:', data.error);
    }

  };

  const handlePlayPause = async () => {

    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      if (!audioRef.current) {
        const response = await fetchTTS(output);

        if (!response) return;

        const audio = new Audio();
        audio.src = window.URL.createObjectURL(await response.blob());

        audioRef.current = audio;

        audioRef.current?.play();

      } else {
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };


  return (
      <Card
        display={convertedOutput ? 'flex' : 'none'}
        p={0}
        w="100%"
        px="22px !important"
        pl="22px !important"
        py={3}
        minH="auto"
        fontSize={{ base: 'sm', md: 'md' }}
        lineHeight={{ base: '24px', md: '26px' }}
        fontWeight="500"
        bg={props?.bg || ''}
        alignItems={'start'}
      >
        {
          message.role !== 'user' &&
          <ButtonGroup position="relative" top="-2px" left="-8px" pb={2}>

            <IconButton
              aria-label={isPlaying ? "Pause" : "Play"}
              icon={isPlaying ? <MdPause /> : <MdPlayArrow />}
              onClick={handlePlayPause}
              ms="auto"
              width="20px"
              height="25px"
              color={fontColor}
              border={'1px'}
              borderColor={"#f3f4f6"}
            />

            <IconButton
              aria-label="Copy message"
              icon={isCopied ? <MdCheck /> : <MdContentCopy />}
              onClick={handleCopy}
              ms="auto"
              width="20px"
              height="25px"
              color={isCopied ? "green.500" : fontColor}
              border={'1px'}
              borderColor={"#f3f4f6"}
              transition="all 0.2s"
              _hover={{
                bg: isCopied ? "green.100" : "gray.100"
              }}
            />

          </ButtonGroup>
        }

        <ReactMarkdown
          className={`font-medium prose min-w-[100%] max-w-[100%] ${fontColor}`}
          skipHtml={false}
          disallowedElements={[]}
          remarkPlugins={[
            remarkGfm,
            remarkLintListItemIndent,
            remarkMath,
          ]}
          rehypePlugins={[
            rehypeKatex,
          ]}
          components={components}
        >
          {convertedOutput ? convertedOutput : ''}
        </ReactMarkdown>

      </Card>
  )
}
