import { configureStore } from "@reduxjs/toolkit";
import {
    useGetTestsQuery, useGetTestQuery, useCreateSessionMutation,
    useGetQuestionsQuery, useSubmitAnswerMutation, useGetSessionsQuery,
    selectAllSessions, selectSessionById, selectAllTests, selectTestById,
    usePurchaseTestMutation, useGetSessionQuery, useEditSessionMutation,
    useGetFullTestQuery, useEditTestMutation, useEditTestReorderQuestionsMutation,
    useGetTestsByAuthorIdQuery, useCreateTestMutation, useGetTestsAdminQuery,
    useEditTestAdminMutation, useCreateRatingMutation, useGetRatingsQuery,
    useEditRatingMutation, useDeleteRatingMutation, useGetRatingQuery,
    useCreateOrderMutation, useCapturePaymentMutation, useGetFlashcardDeckQuery,
    usePostAICreateThreadMutation, usePostAISendMessageMutation,
    usePostAIGenerateFollowUpMutation, useGetThreadsByUserIdQuery, useGetMessagesByThreadIdQuery,
    useGetProfileQuery, useEditProfileMutation, usePostBeePrepGenerateQuestionsMutation,
    useGetBeePrepQuestionsByThreadIdQuery, usePostFeedbackMutation,
} from "./testsSlice";
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from "./apiSlice";
import chatSlice from "./chatSlice";
import beeBotSlice from "./beeBotSlice";
import { createSelector } from 'reselect';
import beePrepSlice from "./beePrepSlice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        chat: chatSlice,
        beebot: beeBotSlice,
        beeprep: beePrepSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(apiSlice.middleware)

})

setupListeners(store.dispatch);

// Set up a listener to log the state whenever it changes
// store.subscribe(() => {
//     console.log('Store state:', store.getState());
// });

const selectAllAnswers = (state, id) => state.tests.find(test => test.id === id)
// Selector for BeeBot AI model
const selectBeeBotModel = (state) => state.beebot.model;
// Selector for BeeBot activeThreadId
const selectActiveThreadId = (state) => state.beebot.activeThreadId;

// Input selector: gets the threads from the state
const selectBeeBotThreads = (state) => state.beebot?.threads || {};

// Output selector: gets the messages for a specific threadId
const selectBeeBotMessagesByThreadId = createSelector(
    [selectBeeBotThreads, (state, threadId) => threadId],
    (threads, threadId) => {
        const thread = threads[threadId];
        return thread ? thread.messages : [];
    }
);


// lastEvaluatedKey selector by threadId
const selectLastEvaluatedKeyByThreadId = createSelector(
    [selectBeeBotThreads, (state, threadId) => threadId],
    (threads, threadId) => {
        const thread = threads[threadId];
        return thread ? thread.lastEvaluatedKey : null;
    }
);

const selectSearch = (state) => state.beebot.search;

// BeePrep Selectors
// Input selector: gets the threads from the state
const selectBeePrepThreads = (state) => state.beeprep?.threads || {};

// Output selector: gets the messages for a specific threadId
const selectBeePrepQuestionsByThreadId = createSelector(
    [selectBeePrepThreads, (state, threadId) => threadId],
    (threads, threadId) => {
        const thread = threads[threadId];
        return thread ? thread.questions : [];
    }
);

// get formData Selector by ThreadId
const selectBeePrepFormDataByThreadId = createSelector(
    [selectBeePrepThreads, (state, threadId) => threadId],
    (threads, threadId) => {
        const thread = threads[threadId];
        return thread ? thread.formData : {};
    }
);


export {
    store, useGetTestsQuery, useGetTestQuery, useCreateSessionMutation,
    useGetQuestionsQuery, useSubmitAnswerMutation, selectAllAnswers,
    usePurchaseTestMutation, useGetSessionsQuery, selectAllSessions,
    selectSessionById, selectAllTests, selectTestById, useGetSessionQuery,
    useEditSessionMutation, useGetFullTestQuery, useEditTestMutation,
    useEditTestReorderQuestionsMutation, useGetTestsByAuthorIdQuery,
    useCreateTestMutation, useGetTestsAdminQuery, useEditTestAdminMutation,
    useCreateRatingMutation, useGetRatingsQuery, useEditRatingMutation,
    useDeleteRatingMutation, useGetRatingQuery,
    useCreateOrderMutation, useCapturePaymentMutation,
    useGetFlashcardDeckQuery, usePostAICreateThreadMutation, usePostAISendMessageMutation,
    usePostAIGenerateFollowUpMutation,
    selectBeeBotModel, selectBeeBotMessagesByThreadId, selectActiveThreadId,
    useGetThreadsByUserIdQuery, useGetMessagesByThreadIdQuery,
    selectSearch, useGetProfileQuery, useEditProfileMutation,
    selectLastEvaluatedKeyByThreadId,
    usePostBeePrepGenerateQuestionsMutation,
    selectBeePrepQuestionsByThreadId, useGetBeePrepQuestionsByThreadIdQuery, selectBeePrepFormDataByThreadId,
    usePostFeedbackMutation,
};