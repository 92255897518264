import { ReactNode } from "react";

export type OpenAIModel = {
  id: string;
  name: string;
  displayText: string,
  displayBadge: string,
  displayBadgeStyle: string,
  iconText?: string,
  iconBadge?: string,
  iconBadgeStyle?: string,
  provider: string;
  maxTokens?: number;
  reasoningEffort?: string[];
  isAvailable: boolean;
  endpoint: string;
  acceptFiles: boolean;
  acceptImages: boolean;
  description?: string;
  inputPrice?: number,
  outputPrice?: number,
};


export interface ChatBody {
  inputCode: string,
  model?: string,
  apiKey?: string | undefined,
  messages?: Message[],
  endpoint: string,
}

export interface AssistantBody {
  inputCode: string,
  model?: string,
  endpoint: string,
  apiKey?: string | undefined,
  maxPromptTokens?: number,
  maxCompletionTokens?: number,
  threadId?: string | undefined | null,
  imageUrls?: FileAndImageUrl[],
  fileUrls?: FileAndImageUrl[],
}

export interface Message {
  role: string,
  content: string,
  id: string,
  messageTimestamp: string,
  model: string,
}

export const ENDPOINTS = {
  OPENAI_CHAT_COMPLETIONS: 'openai-chatCompletions',
  OPENAI_ASSISTANT: 'openai-assistant',
  OPENAI_TTS: 'openai-tts',
}

export interface BeeBotProps {
  apiKeyApp?: string,
  threadId?: string,
  helpRequest?: string,
}

export interface FileAndImageUrl {
  url: string;
  key: string;
}