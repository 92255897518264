import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";

interface SliderInputProps {
    value: number;
    setValue: (value: number) => void;
    defaultValue?: number;
    min?: number;
    max?: number;
    step?: number;
    label?: string;
}

{/*Slider input - Controlled component*/ }
const SliderInput: React.FC<SliderInputProps> = ({
    value,
    setValue,
    defaultValue = 2000,
    min = 100,
    max = 20000,
    step = 100,
    label = "Value",
}) => {
    // const [value, setValue] = useState<number>(defaultValue);

    const handleChange = (_: string, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Flex alignItems="center" rounded={'xl'} width={'auto'}>
            <Text fontSize="sm" mx={2}>{label}:</Text>
            <NumberInput
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={handleChange}
                fontSize="sm"
                maxW="100px"
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </Flex>
    );
};

export default SliderInput;
