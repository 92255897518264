import { Container, Box, SimpleGrid, Heading, Text, Icon, Stack, Card, CardBody, CardHeader, Flex, List, ListItem } from "@chakra-ui/react";
import { Container as ContainerSemantic } from "semantic-ui-react";

import { featuresAI } from "../../constants/featuresAI";

const FeatureSection = () => {
    return (
        <ContainerSemantic>
            <div className="relative mt-10 min-h-[460px]">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
                        Powering your {" "}
                        <span className="bg-gradient-to-r from-emerald-500 to-emerald-800 text-transparent bg-clip-text">
                            Work and Study
                        </span>
                        {" "} with {" "}
                        <span className="bg-gradient-to-r from-purple-500 to-purple-800 text-transparent bg-clip-text">
                            Accessible and Affordable AI
                        </span>
                    </h2>
                </div>

                <div className="flex flex-wrap mt-10 lg:mt-12 text-xl">
                    {featuresAI.map((feature, index) => (
                        <div key={index} className="w-full sm:w-1/2 md:w-2/4">
                            <div className="flex mb-6">
                                <div className="flex mx-4 h-36 w-36 justify-center items-start rounded-full">
                                    {feature.icon}
                                </div>
                                <div>
                                    <p className="mt-1 mb-6 text-xl font-bold">{feature.title}</p>
                                    <p className="text-md p-2 mb-10 text-neutral-500">
                                        {feature.description}
                                    </p>
                                    {/* <ul className="list-disc list-inside">
                                        {feature.highlightedBenefits.map((benefit, index) => (
                                            <li key={index} className="text-md text-neutral-500">
                                                {benefit}
                                            </li>
                                        ))}
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </ContainerSemantic>

    );
};

export default FeatureSection;
