import React from 'react';
import {
    Menu,
    MenuButton,
    Button,
    MenuList,
    Flex,
    IconButton,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { MdAutoAwesome, MdMenu, MdSettings } from 'react-icons/md';
import ModelChange from '../navbar/ModelChange'
import SliderInput from '../sliderInput/SliderInput';
import FeedbackButton from '../feedbackButton/FeedbackButton';
import { Divider } from 'semantic-ui-react';

interface Props {
    maxPromptTokens: number,
    setMaxPromptTokens: React.Dispatch<React.SetStateAction<number>>,
    maxCompletionTokens: number,
    setMaxCompletionTokens: React.Dispatch<React.SetStateAction<number>>,
    activeThreadId?: string,
}

const TokenSettingsButton = ({ maxPromptTokens, setMaxPromptTokens, maxCompletionTokens, setMaxCompletionTokens, activeThreadId }: Props) => {

    // Chakra Color Mode
    const menuBg = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '0px 41px 75px #081132',
    );
    const bgColorIcon = useColorModeValue(
        '#f1f5f9',
        '#1e293b'
    )

    return (
        <Menu>
            <MenuButton p="0px">
                <Button
                    aria-label="Insert photo"
                    w={'fit'}
                    variant="secondary"
                    bg={bgColorIcon}
                >
                    <MdSettings />
                </Button>
            </MenuButton>
            <MenuList
                boxShadow={shadow}
                p="20px"
                me={{ base: '30px', md: 'unset' }}
                borderRadius="20px"
                bg={menuBg}
                border="none"
                mt="22px"
                minW={{ base: 'unset' }}
                maxW={{ base: '360px', md: 'unset' }}
            >
                <Flex flexDirection="column" gap={3} justify={'start'}>

                    <Text fontWeight="500" fontSize="sm" m={2}>
                        Set Token Limits:
                    </Text>

                    <SliderInput
                        value={maxPromptTokens}
                        setValue={setMaxPromptTokens}
                        defaultValue={2000}
                        min={1000}   // max_prompt_tokens: expected a value >= 256, minimum token limit set by OpenAI, 1000 is a good starting point, API stops working below 500
                        max={128000}
                        step={500}
                        label={"Input / Context Max Tokens"}
                    />
                    <SliderInput
                        value={maxCompletionTokens}
                        setValue={setMaxCompletionTokens}
                        defaultValue={2000}
                        min={100}
                        max={128000}
                        step={100}
                        label={"Completions Max Tokens "}
                    />
                    {
                        activeThreadId &&
                        <Text
                            // border="1px"            // Light border
                            // borderColor="#f1f5f9"  // Light gray border color
                            paddingX="8px"           // Padding for content inside the box
                            // margin="auto"
                            alignItems="center"
                            display="flex"
                            height="100%"
                            borderRadius="xl"       // Border radius for slight rounding
                            fontSize={'small'}
                        >
                            <span className='font-bold mr-1'>ThreadId:</span> {activeThreadId.slice(0, 26)}...
                        </Text>
                    }

                </Flex>
                    <Divider />
                    {/* // Feedback Button to report bugs */}
                    <FeedbackButton />

            </MenuList>
        </Menu>
    );
};

export default TokenSettingsButton;
