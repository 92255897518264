import { ENDPOINTS } from "../components/BeeBot/types/types";

export const AI_MODELS = [
  // DEFAULT MODEL - first model on the array will be the default model
  {
    id: 'gpt-4o-mini',
    name: 'GTP-4o mini',
    displayText: 'GPT-4o',
    displayBadge: 'mini',
    displayBadgeStyle: 'text-sm ml-1 font-bold text-emerald-500 superscript mb-3',
    provider: 'OpenAI',
    maxTokens: 16384,
    isAvailable: true,
    endpoint: ENDPOINTS.OPENAI_ASSISTANT,
    acceptFiles: true,
    acceptImages: true,
    description: 'Fast, affordable model for focused tasks',
    inputPrice: 0.18,
    outputPrice: 0.72,
    iconText: '4o',
    iconBadge: '-m',
    iconBadgeStyle: 'text-sm font-bold text-emerald-500',
  },
  // id: 'gpt-4o-mini', name: 'gpt-4o mini'
  {
    id: 'gpt-4o',
    name: 'GPT-4o',
    displayText: 'GPT-',
    displayBadge: '4o',
    displayBadgeStyle: 'text-md font-bold text-emerald-500',
    provider: 'OpenAI',
    maxTokens: 16384,
    isAvailable: true,
    endpoint: ENDPOINTS.OPENAI_ASSISTANT,
    acceptFiles: true,
    acceptImages: true,
    description: 'Versatile, high-intelligence flagship model',
    inputPrice: 3.00,
    outputPrice: 12.00,
    iconText: '4o',
    iconBadge: '',
    iconBadgeStyle: 'text-sm font-bold text-emerald-500',
  },
  {
    id: 'o1-mini-2024-09-12',
    name: 'o1 mini',
    displayText: 'o1',
    displayBadge: 'mini',
    displayBadgeStyle: 'ml-1 text-sm font-bold text-purple-500 superscript mb-3',
    provider: 'OpenAI',
    maxTokens: 65536,
    isAvailable: true,
    endpoint: ENDPOINTS.OPENAI_CHAT_COMPLETIONS,
    acceptFiles: false,
    acceptImages: false,
    description: 'Fast and affordable reasoning model for specialized tasks',
    inputPrice: 3.60,
    outputPrice: 14.40,
    iconText: 'o1',
    iconBadge: '-m',
    iconBadgeStyle: 'text-sm font-bold text-purple-500',
  },
  {
    id: 'o1-preview-2024-09-12',
    name: 'o1 preview',
    displayText: 'o1',
    displayBadge: 'preview',
    displayBadgeStyle: 'ml-1 text-sm font-bold text-purple-500',
    provider: 'OpenAI',
    maxTokens: 100000,
    reasoningEffort: ['low', 'medium', 'high'],
    isAvailable: true,
    endpoint: ENDPOINTS.OPENAI_CHAT_COMPLETIONS,
    acceptFiles: false,
    acceptImages: true,
    description: 'Reasoning model designed to solve hard problems accross domains',
    inputPrice: 18.00,
    outputPrice: 72.00,
    iconText: 'o1',
    iconBadge: '-p',
    iconBadgeStyle: 'text-sm font-bold text-purple-500',
  },

];

// Helper function to find a model by ID
export const getModelById = (modelId) => {
  return AI_MODELS.find(model => model.id === modelId);
};

// Helper function to get all available models
export const getAvailableModels = () => {
  return AI_MODELS.filter(model => model.isAvailable);
};
