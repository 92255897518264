// hooks.ts
import { useSelector } from 'react-redux';
import { selectBeeBotMessagesByThreadId, selectLastEvaluatedKeyByThreadId } from '../../../store/store';

// Custom hook to use the selector with additional arguments
export const useBeeBotMessagesByThreadId = (threadId) => {
  return useSelector((state) =>
    selectBeeBotMessagesByThreadId(state, threadId)
  );
};

// Custom hook to use the selector with additional arguments
export const useLastEvaluatedKeyByThreadId = (threadId) => {
  return useSelector((state) =>
    selectLastEvaluatedKeyByThreadId(state, threadId)
  );
};