import { Icon, Image } from '@chakra-ui/react'; // Import Icon for Chakra UI

export const featuresAI = [
  {
    icon: <Image src='https://www.elegantthemes.com/images/icons/big/editor.svg' />,
    title: "Access the Best AI Models for Your Needs",
    description: "Leverage creative text generation and advanced multimodal understanding with leading AI models from OpenAI, Google Gemini, and Anthropic. Choose the perfect AI for your specific needs.",
    highlightedBenefits: [
      "Creative text generation with OpenAI",
      "Advanced multimodal understanding with Google Gemini",
      "Safety-focused interactions with Anthropic",
    ],
  },
  {
    icon: <Image centered src='https://www.elegantthemes.com/images/icons/big/options.svg'/>,
    title: "Pay-As-You-Go Pricing, No Subscriptions: Unmatched Value",
    description: "Only pay for what you use. No restrictive subscriptions. Transparent pricing structure cost-plus-20%. Enjoy exceptiona and no hidden fees.",
    highlightedBenefits: [
      "Only pay for what you use",
      "No restrictive subscriptions",
      "Transparent pricing structure",
    ],
  },
  {
    icon: <Image centered src='https://www.elegantthemes.com/images/icons/big/responsive.svg'/>,
    title: "Diverse Applications, Limitless Potential",
    description: "Transform your business or enhance your learning with our versatile platform. Explore AI-driven applications tailored to your needs. Intelligent chatbots, personalized tutoring, real-time conversational AI, and interactive language practice.",
    highlightedBenefits: [
      "Intelligent chatbots for customer engagement",
      "Personalized tutoring & exam preparation",
      "Real-time conversational AI for support",
      "Interactive language practice",
    ],
  },
  {
    icon: <Image src='https://www.elegantthemes.com/images/icons/big/history.svg' />,
    title: "Transparent and Affordable",
    description: "Our commitment to open and honest pricing allows you access to advanced AI technology at affordable rates.Access to cutting-edge AI innovation. No hidden costs, ever.",
    highlightedBenefits: [
      "Cost + 20% markup model",
      "Access to cutting-edge AI innovation",
      "No hidden costs, ever",
    ],
  },
];
