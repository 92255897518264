import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
    Box,
    FormControl,
    FormLabel,
    Select,
    Button,
    VStack,
    Textarea,
    useDisclosure,
    useBreakpointValue,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
    Input,
    Text,
    Flex,
    Spacer,
    IconButton,
    Tooltip,
    Link,
    useColorMode,
    useColorModeValue,
    FormErrorMessage,
    FormErrorIcon,
    useToast,
    Icon,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { usePostBeePrepGenerateQuestionsMutation } from '../../store/testsSlice';
import { useDispatch } from 'react-redux';
import { addFormDataBeePrep, addQuestionsBeePrepFromAPI } from '../../store/beePrepSlice';


const MenuDropdown = ({ existingFormState = null, isGenerateNew = true }) => {
    const { handleSubmit, control, formState: { errors } } = useForm({
        mode: 'onBlur', // or 'onChange' if you want validation to trigger on every change
        defaultValues: existingFormState || {},
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();

    let threadId = useParams()?.threadId ?? null;

    // API call to generate BeePrep practice questions
    const [generatePracticeQuestions, { data, isLoading, isError, isSuccess, error }] = usePostBeePrepGenerateQuestionsMutation();

    const handleOnSubmit = async (formData) => {
        try {
            // console.log('formData', formData)

            const response = await generatePracticeQuestions({ formData, threadId }).unwrap();
            // console.log({ results: response });

            dispatch(addQuestionsBeePrepFromAPI(response))

            threadId = response.threadId; // API returns a threadId

            // add formData to threadId
            dispatch(addFormDataBeePrep({ threadId, formData }));

            onClose(); // Close the drawer
            toast({
                title: "Test created",
                description: "Redirecting to your practice questions...",
                status: "success",

                // render: () => (
                //     <Box color="white" p={3} bg="green.500" borderRadius="md">
                //         <Text fontWeight="bold">Test created</Text>
                //         <Text>Redirecting to your practice questions...</Text>
                //     </Box>
                // ),
                duration: 2000,
                isClosable: true,
            });
            // conditional navigation
            if (isGenerateNew) {
                setTimeout(() => {
                    navigate(`${threadId}`);
                }, 2000);
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to generate practice questions",
                status: "error",
                duration: 3000,
                isClosable: true,
                // Add these properties for white text
                color: "white",
            });
        }
    }


    // Use Chakra UI's useBreakpointValue to set dynamic width
    const drawerWidth = useBreakpointValue({ base: '90%', md: '50%', lg: '40%' });

    const bgColor = useColorModeValue(
        'white',
        'navy.800'
    );

    return (

        <>
            {isGenerateNew
                ?
                <Button
                    // colorScheme="teal"
                    onClick={onOpen}
                    leftIcon={<AddIcon />}
                    variant="primary"
                    alignSelf="flex-start"
                    borderRadius={'full'}
                    size={{ base: 'md', lg: 'lg' }}
                >
                    Create New Test
                </Button>
                :
                <Tooltip label="Adjust AI Generation Settings">
                    <Button
                        onClick={onOpen}
                        leftIcon={
                            <Icon viewBox="0 0 24 24" boxSize={5}>
                                <path
                                    fill="currentColor"
                                    d="M7.5,5.6L5,7L6.4,4.5L5,2L7.5,3.4L10,2L8.6,4.5L10,7L7.5,5.6M19.5,15.4L22,14L20.6,16.5L22,19L19.5,17.6L17,19L18.4,16.5L17,14L19.5,15.4M22,2L20.6,4.5L22,7L19.5,5.6L17,7L18.4,4.5L17,2L19.5,3.4L22,2M13.34,12.78L15.78,10.34L13.66,8.22L11.22,10.66L13.34,12.78M14.37,7.29L16.71,9.63C17.1,10 17.1,10.65 16.71,11.04L5.04,22.71C4.65,23.1 4,23.1 3.63,22.71L1.29,20.37C0.9,20 0.9,19.35 1.29,18.96L12.96,7.29C13.35,6.9 14,6.9 14.37,7.29Z"
                                />
                            </Icon>
                        }
                        colorScheme="teal"
                        variant="outline"
                    >
                        <Box display={{
                            base: 'none',
                            md: 'block'
                        }}
                        >
                            Adjust AI Settings
                        </Box>
                    </Button>
                </Tooltip>
            }

            <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
                <DrawerOverlay />
                <DrawerContent width={drawerWidth} bg={bgColor} >
                    <DrawerCloseButton />
                    <DrawerHeader>Select Test Options</DrawerHeader>

                    <DrawerBody>
                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                        <VStack spacing={4}>
                            <FormControl id="testCategory" isRequired isInvalid={errors.testCategory}>
                                <FormLabel>Test Category</FormLabel>
                                <Controller
                                    name="testCategory"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Please select a test category" }}
                                    render={({ field }) => (
                                        <Select placeholder="Select test category" {...field}>
                                            <option value="aws">AWS</option>
                                            <option value="gcp">GCP</option>
                                            <option value="azure">Azure</option>
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>
                                    <FormErrorIcon />
                                    {errors.testCategory && errors.testCategory.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl id="testName" isRequired isInvalid={errors.testName}>
                                <FormLabel>Test Name</FormLabel>
                                <Controller
                                    name="testName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Please select a test name" }}
                                    render={({ field }) => (
                                        <Select placeholder="Select test name" {...field}>
                                            <option value="AWS-Certified-Solutions-Architect-Associate-cl-02">AWS Certified Solutions Architect - Associate</option>
                                            <option value="gcp">AWS Certified Developer - Associate</option>
                                            <option value="azure">AWS Certified Solutions Architect - Professional</option>
                                        </Select>
                                    )}
                                />
                                <FormErrorMessage>
                                    <FormErrorIcon />
                                    {errors.testName && errors.testName.message}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl id="topic">
                                <FormLabel>Topic</FormLabel>
                                <Controller
                                    name="topic"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select placeholder="Select topic" {...field}>
                                            <option value="networking">Networking</option>
                                            <option value="security">Security</option>
                                            <option value="compute">Compute</option>
                                        </Select>
                                    )}
                                />
                            </FormControl>

                            <FormControl id="difficulty">
                                <FormLabel>Difficulty</FormLabel>
                                <Controller
                                    name="difficulty"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select placeholder="Select difficulty" {...field}>
                                            <option value="easy">Easy</option>
                                            <option value="medium">Medium</option>
                                            <option value="hard">Hard</option>
                                        </Select>
                                    )}
                                />
                            </FormControl>

                            <FormControl id="numQuestions">
                                <FormLabel>Number of Questions</FormLabel>
                                <Controller
                                    name="numQuestions"
                                    control={control}
                                    defaultValue={1}
                                    render={({ field }) => (
                                        <Select placeholder="Select number of questions" {...field}>
                                            <option value="1">1</option>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                        </Select>
                                    )}
                                />
                            </FormControl>

                            <FormControl id="instructions">
                                <FormLabel>Additional Instructions</FormLabel>
                                <Controller
                                    name="instructions"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <Textarea placeholder="Enter instructions" {...field} />}
                                />
                            </FormControl>
                        </VStack>
                        {/* </form> */}
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            // colorScheme="teal"
                            variant={'primary'}
                            onClick={handleSubmit(handleOnSubmit)}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                            leftIcon={
                                <Icon viewBox="0 0 24 24" boxSize={5}>
                                    <path
                                        fill="currentColor"
                                        d="M7.5,5.6L5,7L6.4,4.5L5,2L7.5,3.4L10,2L8.6,4.5L10,7L7.5,5.6M19.5,15.4L22,14L20.6,16.5L22,19L19.5,17.6L17,19L18.4,16.5L17,14L19.5,15.4M22,2L20.6,4.5L22,7L19.5,5.6L17,7L18.4,4.5L17,2L19.5,3.4L22,2M13.34,12.78L15.78,10.34L13.66,8.22L11.22,10.66L13.34,12.78M14.37,7.29L16.71,9.63C17.1,10 17.1,10.65 16.71,11.04L5.04,22.71C4.65,23.1 4,23.1 3.63,22.71L1.29,20.37C0.9,20 0.9,19.35 1.29,18.96L12.96,7.29C13.35,6.9 14,6.9 14.37,7.29Z"
                                    />
                                </Icon>
                            }
                        >
                            Generate
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>

        // <Box p={4} maxWidth="400px" mx="auto">
        //   <form onSubmit={handleSubmit(onSubmit)}>
        //     <VStack spacing={4}>
        //       <FormControl id="testType">
        //         <FormLabel>Test Type</FormLabel>
        //         <Controller
        //           name="testType"
        //           control={control}
        //           defaultValue=""
        //           render={({ field }) => (
        //             <Select placeholder="Select test type" {...field}>
        //               <option value="aws">AWS</option>
        //               <option value="gcp">GCP</option>
        //               <option value="azure">Azure</option>
        //             </Select>
        //           )}
        //         />
        //       </FormControl>

        //       <FormControl id="topic">
        //         <FormLabel>Topic</FormLabel>
        //         <Controller
        //           name="topic"
        //           control={control}
        //           defaultValue=""
        //           render={({ field }) => (
        //             <Select placeholder="Select topic" {...field}>
        //               <option value="networking">Networking</option>
        //               <option value="security">Security</option>
        //               <option value="compute">Compute</option>
        //             </Select>
        //           )}
        //         />
        //       </FormControl>

        //       <FormControl id="difficulty">
        //         <FormLabel>Difficulty</FormLabel>
        //         <Controller
        //           name="difficulty"
        //           control={control}
        //           defaultValue=""
        //           render={({ field }) => (
        //             <Select placeholder="Select difficulty" {...field}>
        //               <option value="easy">Easy</option>
        //               <option value="medium">Medium</option>
        //               <option value="hard">Hard</option>
        //             </Select>
        //           )}
        //         />
        //       </FormControl>

        //       <FormControl id="numQuestions">
        //         <FormLabel>Number of Questions</FormLabel>
        //         <Controller
        //           name="numQuestions"
        //           control={control}
        //           defaultValue=""
        //           render={({ field }) => (
        //             <Select placeholder="Select number of questions" {...field}>
        //               <option value="10">10</option>
        //               <option value="20">20</option>
        //               <option value="30">30</option>
        //             </Select>
        //           )}
        //         />
        //       </FormControl>

        //       <FormControl id="instructions">
        //         <FormLabel>Additional Instructions</FormLabel>
        //         <Controller
        //           name="instructions"
        //           control={control}
        //           defaultValue=""
        //           render={({ field }) => <Textarea placeholder="Enter instructions" {...field} />}
        //         />
        //       </FormControl>

        //       <Button type="submit" colorScheme="teal" width="full">
        //         Submit
        //       </Button>
        //     </VStack>
        //   </form>
        // </Box>
    );
};

export default MenuDropdown;
