import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Box, Stack, Text, IconButton, Flex } from "@chakra-ui/react";
import { FC } from "react";

interface TokenUsageDisplayProps {
    inputTokens?: number;
    outputTokens?: number;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

const TokenUsageDisplay: FC<TokenUsageDisplayProps> = ({ inputTokens, outputTokens, isVisible, setIsVisible }) => {

    const shouldShowTokens = typeof inputTokens !== 'undefined' &&
        typeof outputTokens !== 'undefined' &&
        isVisible;

    return (
        <Box
            display={{ base: "none", md: "flex" }} // Hide on small screens, show from medium breakpoint up
            alignItems="center"
            ml={2}
        >
            {shouldShowTokens && (
                <Flex
                    // bg="gray.700"
                    p={2}
                    borderRadius="md"
                    alignItems="center"
                    fontSize="sm"
                >
                    <Stack direction="row" spacing={3}>
                        <Text color="gray.500">
                            Input: <Text as="span" fontWeight="bold" color="green.400">{inputTokens}</Text>
                        </Text>
                        <Text color="gray.500">
                            Output: <Text as="span" fontWeight="bold" color="blue.400">{outputTokens}</Text>
                        </Text>
                    </Stack>
                </Flex>
            )}
            <IconButton
                aria-label="Toggle token display"
                icon={isVisible ? <ViewOffIcon /> : <ViewIcon />}
                size="sm"
                variant="ghost"
                ml={2}
                onClick={() => setIsVisible(!isVisible)}
            />
        </Box>
    );
};

export default TokenUsageDisplay;