import React from 'react';
import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    useDisclosure,
    useColorModeValue,
    Stack,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Image,
    useBreakpointValue
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import BeeImage from '../../images/bee_cartoon_cute_no_background_square_100px_100px.png'
import { navItems } from '../../constants/navItems';


const fontSizes = {
    base: '14px',
    sm: '15px',
    md: '16px',
    lg: '16px',
    xl: '18px'
}


const NavLink = ({ children, href }) => {

    // Dynamic font size for nav links
    const fontSize = useBreakpointValue(fontSizes);

    return (
        <Link
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                color: "#FF5733"
            }}
            _active={{ color: "#C13D1D" }}
            href={href}
            color={useColorModeValue('gray.700', 'gray.300')} // Text color
            fontSize={fontSize}
            fontWeight="medium"
        >
            {children}
        </Link>
    );
}

const NavbarV2 = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const maxWidth = useBreakpointValue({ base: '100%', md: '1800px' });
    const backgroundColor = useColorModeValue('white', 'gray.800'); // Background color

    return (
        <Flex
            position="sticky"
            top="0"
            zIndex="sticky"
            px={4}
            py={2}
            boxShadow="sm"
            maxW={maxWidth}
            mx="auto"
        > {/* Added box shadow */}
            <Box
                maxW={maxWidth}
                mx="auto"
                px={4}
                py={2}
                width="100%"
            >

                <Flex h={16} alignItems={'center'} justifyContent={'space-between'} width="100%" flex={1}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <Box fontWeight="bold" fontSize="lg">
                            {/* Logo and app name */}
                            <NavLink href="/">
                                <div className="flex items-center flex-shrink-0">
                                    <img className="h-10 w-10 mr-2" src={BeeImage} alt="Logo" />
                                    <span className="text-lg font-bold tracking-tight text-emerald-500">QuizBee</span>
                                    <span className={`text-sm font-bold text-violet-500 superscript mb-3`}>
                                        beta
                                    </span>
                                </div>
                            </NavLink>
                        </Box>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {/* 
                             <NavLink href="#">For artists</NavLink>
                           <Menu>
                                <MenuButton as={Link} rightIcon={<ChevronDownIcon />} _hover={{ textDecoration: "none" }} color={useColorModeValue('gray.700', 'gray.300')}>
                                    Who we serve
                                </MenuButton>
                                <MenuList>
                                    <MenuItem>Item 1</MenuItem>
                                    <MenuItem>Item 2</MenuItem>
                                </MenuList>
                            </Menu> 
                            */}

                            {navItems.map((item, index) => {
                                return (
                                    <NavLink key={index} href={item?.href}>
                                        {item?.label}
                                    </NavLink>
                                )
                            }
                            )}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Button
                            bg="#FF5733" // Use the orange color
                            color="white"
                            _hover={{ bg: "#E04A2B" }}
                            _active={{ bg: "#C13D1D" }}
                            size={'md'}
                            rounded="full"
                        >
                            Try for free
                        </Button>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {navItems.map((item, index) => {
                                return (
                                    <NavLink key={index} href={item?.href}>
                                        {item?.label}
                                    </NavLink>
                                )
                            }
                            )}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </Flex >
    );
};

export default NavbarV2;