import React, { useEffect } from 'react';
import { useGetTestsQuery } from "../store/store";
import Hero from "./LandingPage/Hero";
import ListingProducts from "./ListingProducts";
import Loader from "./Loader";
import { Hub } from 'aws-amplify';
import ReactGA from "react-ga4";
import FeatureSection from "./LandingPage/FeatureSection";
import { Container, Divider, Header } from 'semantic-ui-react';
import ChatBotMessage from './ChatBotMessage';
import message from './Archived/message';
import CardApp from './LandingPage/CardApp';
import HeroV2 from './LandingPage/HeroV2';
import { ChakraProvider, Box, useColorModeValue } from '@chakra-ui/react';
import NavbarV2 from './LandingPage/NavbarV2';
import { navItems } from '../constants/navItems';
import AppSelection from './LandingPage/AppSelection';

ReactGA.initialize("G-5XVHY8M7JK");

// import { ThemeProvider  } from '@aws-amplify/ui-react';
//TODO rerun the getTests query when user logs in - COMPLETED

const App = () => {

    const backgroundColor = useColorModeValue('#f7f7f7', 'gray.800'); // Background color

    const {
        data: tests = [],
        refetch: testsRefetch,
        isLoading: isTestsLoading,
        isFetching: isTestsFetching,
        isSuccess: isTestsSuccess,
        isError: isTestsError,
        error: testsError
    } = useGetTestsQuery();

    useEffect(() => {
        const listener = (data) => {
            // Refetch the data when the authentication status changes
            // console.log('Hub data', data)
            testsRefetch();
        };

        ReactGA.send({
            hitType: "pageview", page: window.location.pathname + window.location.search,
            title: "App"
        });

        // Subscribe to authentication events from AWS Amplify
        Hub.listen('auth', listener);

        // Cleanup function to unsubscribe from the events
        return () => {
            Hub.remove('auth', listener);
        };
    }, [testsRefetch])

    return (
        <ChakraProvider>

            <Box bg={backgroundColor} minH="100vh">
                <NavbarV2 />
                {/* <Hero /> */}
                <HeroV2 />

                <div className='flex flex-row items-center justify-center'>
                    <span className="bg-gray-200 rounded-full h-6 text-base font-medium px-3 py-2 uppercase flex items-center">
                        Featured Apps
                    </span>
                </div>

                <AppSelection apps={navItems} />

                {/* {(isTestsFetching || isTestsLoading) && <Loader />} */}
                {/* {isTestsSuccess && <ListingProducts tests={tests} />} */}

                {/* <Container>
                    <CardApp />
                </Container> */}


                <div className='flex flex-row items-center justify-center'>
                    <span className="bg-gray-200 rounded-full h-6 text-base font-medium px-3 py-2 uppercase flex items-center">
                        Features
                    </span>
                </div>
                <FeatureSection />
            </Box>
        </ChakraProvider>
    );
};

export default App;
