import { createSlice } from '@reduxjs/toolkit';
import { extendedApiSlice } from './testsSlice';
import { useDispatch } from 'react-redux';

const initialState = {
    model: null,
    threads: {},
    activeThreadId: null, // Add activeThreadId to the initial state,
    search: '',
};

const beeBotSlice = createSlice({
    name: 'beebot',
    initialState,
    reducers: {
        setModelBeeBot: (state, action) => {
            state.model = action.payload;
        },
        setThreadIdBeeBot: (state, action) => {
            const { threadId } = action.payload;
            state.threads[threadId] = {
                ...state.threads[threadId],
                threadId,
            };
        },
        addMessageBeeBot: (state, action) => {
            const { threadId, message } = action.payload;
            state.threads[threadId] = {
                ...state.threads[threadId],
                messages: [
                    ...(state.threads[threadId]?.messages || []),
                    { ...message },
                ],
            };
        },
        updateLastMessageBeeBot: (state, action) => {
            const { threadId, newContent } = action.payload;
            const thread = state.threads[threadId];
            if (thread?.messages?.length > 0) {
                const lastIndex = thread.messages.length - 1;
                thread.messages[lastIndex] = {
                    ...thread.messages[lastIndex],
                    content: thread.messages[lastIndex].content + newContent,
                };
            }
        },
        updateThreadIdForDefaultMessages: (state, action) => {
            const { newThreadId } = action.payload;
            const defaultThread = state.threads['default'];
            if (defaultThread?.messages) {
                const updatedMessages = defaultThread.messages.map(message => ({
                    ...message,
                    id: newThreadId,
                }));
                state.threads[newThreadId] = {
                    // ...defaultThread,
                    messages: [
                        ...(state.threads[newThreadId]?.messages || []),
                        ...updatedMessages,
                    ],
                };
                delete state.threads['default'];
            }
        },
        // New reducer to update activeThreadId
        setActiveThreadIdBeeBot: (state, action) => {
            state.activeThreadId = action.payload.threadId;
        },
        // New reducer to update activeThreadId
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        },
        // Add messages to the existing threadId
        addMessagesBeeBot: (state, action) => {
            const { threadId, messages, lastEvaluatedKey } = action.payload;
            if (!state.threads[threadId]) {
                state.threads[threadId] = {};
                state.threads[threadId]['messages'] = [];
            }
            state.threads[threadId]['messages'] = [...state.threads[threadId]['messages'], ...messages];
            state.threads[threadId]['lastEvaluatedKey'] = lastEvaluatedKey;
        },
        logoutBeeBot: (state, action) => {
            return initialState;
        },
    },
});

export const { setModelBeeBot, setThreadIdBeeBot, addMessageBeeBot,
    updateLastMessageBeeBot, updateThreadIdForDefaultMessages, setActiveThreadIdBeeBot,
    setSearchTerm, addMessagesBeeBot, logoutBeeBot } = beeBotSlice.actions;
export default beeBotSlice.reducer;


// const state = {
//     threads : {
//         'threadId 1' : {
//             messages: ['1', '2'],
//             lastEvaluatedKey: 'lastEvaluatedKey'
//         }
//     }
// }