import React from 'react';
import {
    Menu,
    MenuButton,
    Button,
    MenuList,
    Flex,
    IconButton,
    useColorModeValue,
    useDisclosure,
    Text,
    Show,
    Hide,
} from '@chakra-ui/react';
import { MdAutoAwesome } from 'react-icons/md';
import ModelChange from '../navbar/ModelChange'
import { useSelector } from 'react-redux';
import { selectBeeBotModel } from '../../../../store/store';
import ModelDisplay from '../modelDisplay/ModelDisplay';


const ModelSelectButton = () => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const currentModel = useSelector(selectBeeBotModel)

    // Chakra Color Mode
    const menuBg = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '0px 41px 75px #081132',
    );

    const bgColorIcon = useColorModeValue(
        '#f1f5f9',
        '#1e293b'
    )



    return (
        <Menu isOpen={isOpen} onClose={onClose}>
            <MenuButton
                p="0px"
                as={Button}
                w={'fit'}
                aria-label="Select AI Model"
                bg={bgColorIcon}
                onClick={onOpen}
                variant="secondary"
                px={2}
            >
                <Show below="sm">
                    <MdAutoAwesome />
                </Show>
                <Hide below="sm">
                    {currentModel?.iconText ? (
                        <ModelDisplay
                            displayText={currentModel.iconText}
                            displayBadge={currentModel.iconBadge}
                            displayBadgeStyle={currentModel.iconBadgeStyle}
                        />
                    ) : (
                        <MdAutoAwesome />
                    )}
                </Hide>
            </MenuButton>

            <MenuList
                boxShadow={shadow}
                p="20px"
                me={{ base: '30px', md: 'unset' }}
                borderRadius="20px"
                bg={menuBg}
                border="none"
                mt="22px"
                minW={{ base: 'unset' }}
                maxW={{ base: '360px', md: 'unset' }}
            >
                <Flex flexDirection="column">
                    <ModelChange onClose={onClose} />
                </Flex>
            </MenuList>
        </Menu>
    );
};

export default ModelSelectButton;
