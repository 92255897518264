import MessageBox from '../messageBox/MessageBox';
import { AssistantBody, BeeBotProps, ChatBody, ENDPOINTS, FileAndImageUrl, Message, OpenAIModel } from '../../types/types';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  calc,
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  Image,
  Input,
  Text,
  Textarea,
  useColorModeValue,
  TagLabel,
  Code,
  Heading,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState, ClipboardEvent, useMemo, memo } from 'react';
import { MdAutoAwesome, MdBolt, MdEdit, MdPerson, MdKeyboardReturn, MdOutlineAttachFile, MdOutlineInsertPhoto, MdClear, MdOutlineCleaningServices, MdInsertDriveFile } from 'react-icons/md';
import { ChatCompletionStream } from 'openai/lib/ChatCompletionStream';
import { Storage, Auth } from 'aws-amplify';
import { SSE } from 'sse.js';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AssistantStream } from 'openai/lib/AssistantStream';
import { CloseIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import UserMessageBox from '../userMessageBox/UserMessageBox';
import { useBeeBotMessagesByThreadId, useLastEvaluatedKeyByThreadId } from '../../utils/hooks';
import { selectActiveThreadId, selectLastEvaluatedKeyByThreadId, selectSearch, useGetMessagesByThreadIdQuery } from '../../../../store/store';
import { addMessagesBeeBot } from '../../../../store/beeBotSlice';

interface Props {
  editMessage: (message: Message) => void;
}

const message1 = {
  role: 'user',
  content: 'How do I implement a binary search algorithm in Python?',
  attachments: [
    'image1.jpg',
    'image2.png',
    'file1.pdf',
    'file2.docx'
  ],
  id: 'msg1',
  messageTimestamp: new Date().toISOString(),
  model: 'model1',
};

const message2 = {
  role: 'assistant',
  content: 'Can you explain the difference between a stack and a queue?',
  attachments: [
    'diagram1.png',
    'diagram2.jpg',
    'notes.txt',
    'summary.pdf'
  ],
  id: 'msg2',
  messageTimestamp: new Date().toISOString(),
  model: 'model2',
};
const message3 = {
  role: 'user',
  content: 'What are the best practices for securing a REST API?',
  attachments: [
    'security1.png',
    'security2.jpg',
    'checklist.pdf',
    'guide.docx'
  ],
  id: 'msg3',
  messageTimestamp: new Date().toISOString(),
  model: 'model3',
};

const message4 = {
  role: 'assistant',
  content: 'How can I optimize the performance of my React application?',
  attachments: [
    'performance1.png',
    'performance2.jpg',
    'optimization.pdf',
    'tips.docx'
  ],
  id: 'msg4',
  messageTimestamp: new Date().toISOString(),
  model: 'model4',
};


function Messages({ editMessage }: Props) {
  const activeThreadId = useSelector(selectActiveThreadId) || 'default';
  const messages: Message[] = useBeeBotMessagesByThreadId(activeThreadId);

  // Search functionality
  const [searchResults, setSearchResults] = useState<number[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const listRef = useRef<HTMLDivElement>(null);

  // searchTerm from redux store
  const searchTerm: string = useSelector(selectSearch);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm) {
        handleSearch(searchTerm);
      } else {
        setSearchResults([]);
        setCurrentIndex(-1);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);


  const dispatch = useDispatch();
  const lastEvaluatedKey = useLastEvaluatedKeyByThreadId(activeThreadId);
  const { data, error, isLoading, refetch } = useGetMessagesByThreadIdQuery({ threadId: activeThreadId, lastEvaluatedKey }, { skip: !activeThreadId });

  useEffect(() => {
    if (data) {
      const existingMessages = messages || [];
      const newMessages = data.messages.filter(
        (message : Message) => !existingMessages.some((m) => `${m.id}-${m.messageTimestamp}` === `${message.id}-${message.messageTimestamp}`)
      );

      if (newMessages.length > 0) {
        dispatch(
          addMessagesBeeBot({
            threadId: activeThreadId,
            messages: newMessages,
            lastEvaluatedKey: data.lastEvaluatedKey,
          })
        );
      }
    }
  }, [data, dispatch, activeThreadId]);

  const handleScroll = useCallback(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop === 0 && !isLoading && lastEvaluatedKey) {
        refetch();
      }
    }
  }, [isLoading, lastEvaluatedKey, refetch]);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
      return () => {
        listElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  ///////////////////////

  const handleSearch = (term: string) => {
    const content = messages.map(message => message.content).join(' ');
    const regex = new RegExp(term, 'gi');
    const matches = [];
    let match;
    while ((match = regex.exec(content)) !== null) {
      matches.push(match.index);
    }
    setSearchResults(matches);
    setCurrentIndex(matches.length > 0 ? 0 : -1);
  };

  const handleNextHit = () => {
    if (searchResults.length > 0) {
      const nextIndex = (currentIndex + 1) % searchResults.length;
      setCurrentIndex(nextIndex);
      scrollToHit(nextIndex);
    }
  };

  const scrollToHit = (index: number) => {
    // if (listRef.current) {
    //   const content = listRef.current;
    //   const range = document.createRange();
    //   const selection = window.getSelection();
    //   range.setStart(content.firstChild!, searchResults[index]);
    //   range.setEnd(content.firstChild!, searchResults[index] + searchTerm.length);
    //   selection?.removeAllRanges();
    //   selection?.addRange(range);
    //   content.scrollTop = range.getBoundingClientRect().top - content.getBoundingClientRect().top + content.scrollTop;
    // }
  };

  return (
    <>
      <div ref={listRef}>
        {messages.map(message => {
          if (message.role === 'user') {
            {/*User Message */ }
            return (
              <UserMessageBox message={message} key={`${message.id}-${message.messageTimestamp}`} editMessage={editMessage} />
            );
          } else {
            {/* Bot Message */ }
            return (
              <Flex w="100%" align={'center'}  mb="10px" key={`${message.id}-${message.messageTimestamp}`} >
                <Flex
                  borderRadius="full"
                  justify="center"
                  align="center"
                  bg={'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)'}
                  me="20px"
                  h="40px"
                  minH="40px"
                  minW="40px"
                >
                  <Icon
                    as={MdAutoAwesome}
                    width="20px"
                    height="20px"
                    color="white"
                  />
                </Flex>
                <MessageBox message={message} />
              </Flex>
            );
          }
        })}

      </div>
    </>

  );
}

export default Messages;