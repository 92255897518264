import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Image,
  Button,
  Container,
  Flex,
  useColorModeValue,
  useBreakpointValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const ModelCard = ({ imageSrc, title, description, href }) => {

  const navigate = useNavigate();

  return (
    <Box
      borderRadius="lg"
      overflow="hidden"
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow="md"
      transition="transform 0.2s"
      _hover={{ transform: 'scale(1.05)' }}
      onClick={() => navigate(href)}
    >
      <Image src={imageSrc} alt={title} objectFit="cover" w="100%" h="250px" /> {/* Fixed image height */}
      <Box p={4}>
        <Heading size="md" fontWeight="semibold" mb={2} color={useColorModeValue('gray.800', 'white')}>
          {title}
        </Heading>
        <Text fontSize="sm" color={useColorModeValue('gray.600', 'gray.400')}>
          {description}
        </Text>
      </Box>
    </Box>
  );
}

const AppSelection = ({ apps }) => {

  const maxWidth = useBreakpointValue({ base: '100%', md: '1800px' });


  return (
    <Flex direction={'column'} maxWidth={maxWidth} mx="auto" px={{ base: 4, md: 6, lg: 8 }} py={{ base: 8, md: 12, lg: 16 }}>
      <Box width={'100%'} mx="auto">
        <Heading as="h2" size="2xl" fontWeight="bold" mb={4} textAlign="center" color={useColorModeValue('gray.900', 'white')}>
          Select an app to get started
        </Heading>
        <Text fontSize="lg" mb={8} textAlign="center" color={useColorModeValue('gray.700', 'gray.300')}>
          Selection of AI powered apps to turbocharge your work and study.
        </Text>
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} mb={8} mx={{base: 4, lg: 12}}>
          {apps.map((app) => (
            <GridItem key={app.title}>
              <ModelCard {...app} />
            </GridItem>
          ))}
        </Grid>
        <Box textAlign="start">
          <Button colorScheme="gray" variant="outline" disabled={true} cursor={'not-allowed'}>
            Explore all apps
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}

export default AppSelection;