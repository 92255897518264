import {
    Button,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useColorModeValue,
    useDisclosure,
    UseDisclosureReturn,
    Card,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { MdLock } from 'react-icons/md';
// import Card from '../card/Card';
import { Auth, Hub } from 'aws-amplify';
import AuthenticatorAmplify from '../../../AuthenticatorAmplify';
import { useDispatch } from 'react-redux';

interface Props {
    disclosure?: UseDisclosureReturn,
    buttonVisible?: boolean,
}


function LoginModal({ disclosure, buttonVisible = true }: Props) {
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    // Check whether disclosure was passed, otherwise useDisclosure from within component
    const { isOpen: isOpenSelf, onOpen: onOpenSelf, onClose: onCloseSelf } = useDisclosure();
    let isOpen, onOpen, onClose;
    if (disclosure) {
        isOpen = disclosure.isOpen;
        onOpen = disclosure.onOpen;
        onClose = disclosure.onClose;
    } else {
        isOpen = isOpenSelf;
        onOpen = onOpenSelf;
        onClose = onCloseSelf;
    }

    const dispatch = useDispatch();

    // const user = useUserStatus();
    // @ts-ignore
    // const { data, isFetching, isSuccess } = useGetProfileQuery(user?.attributes?.sub, { skip: !user });
    // console.log('user', user)

    const textColor = useColorModeValue('navy.700', 'white');
    const grayColor = useColorModeValue('gray.500', 'gray.500');
    const inputBorder = useColorModeValue('gray.200', 'whiteAlpha.200');
    const inputColor = useColorModeValue('navy.700', 'white');
    const link = useColorModeValue('brand.500', 'white');
    const navbarIcon = useColorModeValue('gray.500', 'white');


    // console.log('isUserLoggedIn', isUserLoggedIn)

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setIsUserLoggedIn(!!user);
            } catch (error) {
                console.error('Error checking user login status:', error);
                setIsUserLoggedIn(false);
            }
        };

        const handleAuthStateChange = (authData: any) => {
            const { payload: { event } } = authData;
            // console.log('eventHub', event)
            if (event === 'signIn' || event === 'signOut') {
                checkUserLoggedIn();
            }
        };

        checkUserLoggedIn();
        const listenerCancelToken = Hub.listen('auth', handleAuthStateChange);

        return () => {
            listenerCancelToken();
        };
    }, []);



    // useEffect(() => {
    //     const fetchUserProfile = async () => {
    //         try {
    //             // Get the authenticated user object
    //             const user = await Auth.currentAuthenticatedUser();

    //             // Get the user ID from the authenticated user object
    //             const userId = user.attributes.sub;

    //             // Dispatch the RTK query to fetch the user profile
    //             // @ts-ignore
    //             dispatch(extendedApiSlice.endpoints.getProfile.initiate(userId));
    //         } catch (error) {
    //             console.log('Error fetching user profile:', error);
    //         }
    //     };

    //     const handleAuthStateChange = (data: any) => {
    //         if (data.payload.event === 'signIn') {
    //             fetchUserProfile();
    //         }
    //     };

    //     // Listen for authentication state changes
    //     const hubListenerCancelToken = Hub.listen('auth', handleAuthStateChange);

    //     // Cleanup function to unsubscribe from the events
    //     return () => {
    //         hubListenerCancelToken();
    //     };
    // // }, [dispatch, user]);
    // }, []);



    return (
        <>
            {buttonVisible &&  (
                <Button
                    onClick={onOpen}
                    minW="max-content !important"
                    px="6px"
                    me="10px"
                    // _hover={{ bg: 'none' }}
                    // _focus={{ bg: 'none' }}
                    // _selected={{ bg: 'none' }}
                    // bg="none !important"
                    variant={'primary'}
                    fontSize={'sm'}
                    fontWeight={'500'}
                    rounded={'lg'}
                >
                    <Icon w="14px" h="14px" as={MdLock} color={'white'} mr={1} />
                    Login
                </Button>
            )}

            <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="none" boxShadow="none" >
                    <Card
                        bg="white"
                        boxShadow="lg" // Changed to lg for a more pronounced shadow
                        borderRadius="lg"
                        px={2}
                        py={6}
                        minW={{ base: "90%", sm: "400px" }} // Responsive width
                        maxW="500px" // Max width for larger screens
                        margin="auto" // Center the Card in the Modal
                    >
                        <ModalHeader
                            fontSize="22px"
                            fontWeight={'700'}
                            mx="auto"
                            textAlign={'center'}
                            color={textColor}
                        >
                            Log In / Sign Up
                        </ModalHeader>
                        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                        <ModalBody >
                            {/* @ts-ignore */}
                            <AuthenticatorAmplify redirectTo={'/beebot'} setModal={onClose} />
                        </ModalBody>
                    </Card>
                </ModalContent>
            </Modal>
        </>
    );
}

export default LoginModal;