import Link from '../components/link/Link';
import { AssistantBody, BeeBotProps, ChatBody, ENDPOINTS, FileAndImageUrl, Message, OpenAIModel } from '../types/types';
import {
  Box,
  Button,
  ButtonGroup,
  calc,
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  Image,
  Input,
  Text,
  Textarea,
  useColorModeValue,
  TagLabel,
  Code,
  Spinner,
  useDisclosure,
  Portal,
  Menu,
  MenuButton,
  MenuList,
  Slide,
  useBreakpointValue,
  Tooltip,
  Kbd,
  useToast
} from '@chakra-ui/react';
import { MouseEvent, MouseEventHandler, useCallback, useEffect, useRef, useState, ClipboardEvent, useMemo } from 'react';
import { MdAutoAwesome, MdBolt, MdEdit, MdPerson, MdKeyboardReturn, MdOutlineAttachFile, MdOutlineInsertPhoto, MdClear, MdOutlineCleaningServices, MdInsertDriveFile, MdArrowDownward } from 'react-icons/md';
import { ChatCompletionStream } from 'openai/lib/ChatCompletionStream';
import { Storage, Auth } from 'aws-amplify';
import { SSE } from 'sse.js';
import { globalStyles } from '../theme/styles';
import { selectActiveThreadId, selectBeeBotModel, usePostAIGenerateFollowUpMutation } from '../../../store/store';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { addMessageBeeBot, setActiveThreadIdBeeBot, updateLastMessageBeeBot, updateThreadIdForDefaultMessages } from '../../../store/beeBotSlice';
import { AssistantStream } from 'openai/lib/AssistantStream';
import SliderInput from '../components/sliderInput/SliderInput';
import ImageUploadButton, { ImageUploadButtonComponentRef } from '../components/imageUploadButton/ImageUploadButton';
import { ArrowDownIcon, CloseIcon } from '@chakra-ui/icons';
import FileUploadButton, { FileUploadButtonComponentRef } from '../components/fileUploadButton/FileUploadButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBeeBotMessagesByThreadId } from '../utils/hooks';
import Messages from '../components/messages/Messages';
import { getFileNameFromS3Key } from '../utils/otherUtils';
import { set } from 'react-hook-form';
import useEmptyTextareaAnimation from '../utils/useEmptyTextareaAnimation';
import LoginModal from '../components/loginModal/LoginModal';
import ModelChange from '../components/navbar/ModelChange';
import ModelSelectButton from '../components/modelSelectButton/ModelSelectButton';
import TokenSettingsButton from '../components/tokenSettingsButton/TokenSettingsButton';
import { extendedApiSlice } from '../../../store/testsSlice';
import { batch } from 'react-redux';
import { debounce } from 'lodash';
import TokenUsageDisplay from '../components/tokenUsageDisplay/TokenUsageDisplay';
import { DropZone } from '@aws-amplify/ui-react-storage/dist/types/components/StorageManager/ui';
import DropzoneV2 from '../components/dropzone/DropzoneV2';
import WelcomeSection from '../components/welcomeSection/WelcomeSection';

// TODO: 1. add threadId and assistantId to the url param

enum LoadingState {
  NotLoading = 'notLoading',
  Loading = 'loading',
  Streaming = 'streaming',
}

interface TokenCount {
  prompt_tokens?: number;
  completion_tokens?: number;
  total_tokens?: number;  // Include if you need it
}

export default function BeeBot({ threadId, helpRequest }: BeeBotProps) {

  // Input States
  const [inputOnSubmit, setInputOnSubmit] = useState<string>('');
  const [inputCode, setInputCode] = useState<string>('');
  // Response message
  const [outputCode, setOutputCode] = useState<string>('');

  // Loading state
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoading);
  // Tokens used
  const [tokens, setTokens] = useState<TokenCount>({});
  //state to show or hide tokens used
  const [showTokens, setShowTokens] = useState<boolean>(true);

  // API Key
  // const [apiKey, setApiKey] = useState<string>(apiKeyApp);
  // control input and output tokens
  const [maxPromptTokens, setMaxPromptTokens] = useState<number>(20000);
  const [maxCompletionTokens, setMaxCompletionTokens] = useState<number>(5000);

  // Welcome section if it's new visit
  const [isFirstVisit, setIsFirstVisit] = useState<boolean>(true);

  // Message buffer for debouncing
  const messageBufferRef = useRef<string>('');

  // threadId state
  const activeThreadId = useSelector(selectActiveThreadId);

  // Messages from redux
  const messages: Message[] = useBeeBotMessagesByThreadId(activeThreadId);

  // Toast
  const toast = useToast();

  // trigger for FollowUpSuggestions event to fire
  const [triggerHandleSendMessage, setTriggerHandleSendMessage] = useState(false);
  // Model
  const model = useSelector(selectBeeBotModel);
  // redux dispatch function
  const dispatch = useDispatch();


  // follow up suggestions
  const [suggestions, setSuggestions] = useState<string[]>([
    "Tell me about the history of Rome",
    "I'm new to programming, teach me about programming concepts",
    "Explain calculus in a simple language with examples ",
    "I just want to talk",
  ]);
  // check whether size of mobile for suggestions box
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false });


  // image uploader state
  const [imageUrls, setImageUrls] = useState<FileAndImageUrl[]>([]);
  const [fileUrls, setFileUrls] = useState<FileAndImageUrl[]>([]);
  // image or file deleting state for smooth transition by imageUrl or fileUrl Key
  const [isDeleting, setIsDeleting] = useState<string>('');
  // Login Modal display state
  const disclosure = useDisclosure();
  // const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  // const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const [suggestionsHeight, setSuggestionsHeight] = useState<number>(0);

  // Controller to abort stream
  const [controller, setController] = useState<AbortController | null>(
    null
  );

  // stop scroll when user typing
  const [isTyping, setIsTyping] = useState<boolean>(false)

  // Save the history of prompts in local storage for faster scroll up and down
  const [promptHistory, setPromptHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const MAX_HISTORY = 20;

  // refs for Image and File uploaders to allow for paste behavior
  const imageUploaderRef = useRef<ImageUploadButtonComponentRef | null>(null);
  const fileUploaderRef = useRef<FileUploadButtonComponentRef | null>(null);

  {/* URL Navigate and Location objects */ }
  // Get the location object from useLocation hook
  const location = useLocation();
  // Get the navigate function from useNavigate hook
  const navigate = useNavigate();

  // adjust bottom margin to avoid overlap with suggestions
  useEffect(() => {
    const updateMargin = () => {
      if (suggestionsRef.current) {
        setSuggestionsHeight(window.innerHeight - suggestionsRef.current.getBoundingClientRect().top);
      }
    };

    updateMargin();

    window.addEventListener('resize', updateMargin);

    return () => {
      window.removeEventListener('resize', updateMargin);
    };
  }, [suggestions]);

  // Redux RTK Query API to generate Follow-Up Suggestions
  const [
    generateFollowUp, {
      isLoading: isLoadingGenerateFollowUp, isError: isErrorGenerateFollowUp,
      isSuccess: isFollowUpSuccess, error: errorGenerateFollowUp,
      data: followUpData
    }
  ] = usePostAIGenerateFollowUpMutation();

  // using debounce to optimize react rendering during streaming to avoid freeze


  // using lodash/debounce (option 1)
  //  import debounce from 'lodash/debounce';
  // const debouncedUpdateMessage = useCallback(
  //   debounce((threadId: string, text: string) => {
  //     dispatch(updateLastMessageBeeBot({ threadId, newContent: text }));
  //     messageBufferRef.current = ''; // Clear the message buffer

  //   }, 100, { maxWait: 300 }), // 100ms delay, max 300ms wait
  //   [dispatch]
  // );


  // using lodash/debounce and react-redux batch (option 2)
  const debouncedUpdateMessage = useCallback(
    debounce((threadId: string, text: string) => {
      batch(() => {
        dispatch(updateLastMessageBeeBot({ threadId, newContent: text }));
        messageBufferRef.current = ''; // Clear the message buffer
      });
    }, 350, { maxWait: 1000 }),
    [dispatch]
  );

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      debouncedUpdateMessage.cancel();
    };
  }, [debouncedUpdateMessage]);

  // generateFollowUp API call with lodash debounce
  // Move the debounced function outside of the effect and memoize it with useCallback
  const debouncedFollowUp = useCallback(
    debounce((
      messages: Message[],
      generateFollowUp: (params: { action: string; messages: Message[] }) => void,
      loadingState: LoadingState
    ) => {
      if (
        messages.length > 1 &&
        messages.at(-1)?.role === 'assistant' &&
        loadingState !== LoadingState.Streaming &&
        loadingState !== LoadingState.Loading
      ) {
        generateFollowUp({
          action: 'generate-follow-up',
          messages: messages.slice(-4),
        });
      }
    }, 500), // 500ms delay
    [] // Empty dependency array since we want this to be stable
  );

  // Use the debounced function in the effect
  useEffect(() => {
    debouncedFollowUp(messages, generateFollowUp, loadingState);

    // Cleanup function to cancel pending debounced calls
    return () => {
      debouncedFollowUp.cancel();
    };
  }, [messages, generateFollowUp, loadingState, debouncedFollowUp]);

  // setFollowUps in the state if API call is successful
  useEffect(() => {
    if (isFollowUpSuccess) {
      try {
        const data = JSON.parse(followUpData.response); // Attempt to parse JSON

        // Check if parsed data is an array and has more than 0 elements
        if (Array.isArray(data) && data.length > 0) {
          setSuggestions([...data]); // Set the valid suggestions to state
        } else {
          console.error("Parsed data is not a valid array or is empty");
        }
      } catch (error) {
        console.error("Invalid JSON received:", error); // Log if JSON parsing fails
      }
    }
  }, [isFollowUpSuccess, followUpData]);

  {/* Local storage for history of prompts */ }
  useEffect(() => {
    // Optional: Save history to localStorage when component unmounts
    return () => {
      localStorage.setItem('promptHistory', JSON.stringify(promptHistory));
    };
  }, [promptHistory]);

  // And load it when component mounts
  useEffect(() => {
    const savedHistory = localStorage.getItem('promptHistory');
    if (savedHistory) {
      setPromptHistory(JSON.parse(savedHistory));
    }
  }, []);


  {/* Input Text Operations */ }
  const resultRef = useRef('');

  useEffect(() => {
    resultRef.current = outputCode;
  }, [outputCode]);

  // dynamic adjusting height of Input text box
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const maxTextAreaHeight = 250;

  const handleInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Resetting height
      textareaRef.current.style.height = textareaRef.current.scrollHeight > maxTextAreaHeight ? `${maxTextAreaHeight}px` : `${textareaRef.current.scrollHeight}px`; // Set height to scroll height
    };
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const isMobilePhone = /Android|iPhone/i.test(navigator.userAgent);

    if (isMobilePhone && event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior
      setIsTyping(false);
      handleTranslate(); // Trigger the submit action
    } else if (!isMobilePhone && (event.ctrlKey || event.metaKey) && event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior
      setIsTyping(false);
      handleTranslate(); // Trigger the submit action
    }

  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    // Update the input value immediately for UI responsiveness
    e.target.value = value;
    // Debounce the state update
    debouncedHandleChange(value);
  };

  // Create a debounced version of the handler
  const debouncedHandleChange = useCallback(
    debounce((value: string) => {
      setInputCode(value);
    }, 300), // 300ms delay
    [] // Empty dependency array since we don't want to recreate this function
  );

  // Clean up the debounced function when component unmounts
  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  {/* Input Text Operations
      Edit previously asked query by copying it into the current text input box */ }
  const copyTextToTextarea = (message: Message) => {

    if (textareaRef.current) {
      setInputCode(message.content);  // include text in the inputCode
      textareaRef.current.value = message.content; // Set the value of the textarea to the inputOnSubmit
      textareaRef.current.focus(); // Focus on the textarea
      textareaRef.current.style.height = 'auto'; // Reset the height to a predefined small height
    }
  };

  {/* Handle API call for the Input Text */ }

  {/* Follow up Suggestions On Click function */ }
  const handleOnFollowUpClick = async (event: MouseEvent<HTMLParagraphElement, globalThis.MouseEvent>, suggestion: string) => {
    event.preventDefault();
    if (suggestion.trim()) {
      // console.log('children', data.children.trim())
      setInputCode(suggestion.trim());
      setTriggerHandleSendMessage(!triggerHandleSendMessage);
    }
  }

  // Animation for Textarea if user submits and it's empty
  const handleEmptySubmit = useEmptyTextareaAnimation(textareaRef);

  {/* Trigger API call for the FollowUpSuggestion */ }
  useEffect(() => {
    if (inputCode) {
      handleTranslate();
    }
  }, [triggerHandleSendMessage])



  {/* handleHelpRequest function */ }
  const handleHelpRequest = async (helpRequest: string) => {
    if (helpRequest.trim()) {
      setInputCode(helpRequest.trim());
      setTriggerHandleSendMessage(!triggerHandleSendMessage);
    }
  }

  {/* Trigger API call for the handleHelpRequest */ }
  useEffect(() => {
    if (helpRequest) {
      handleHelpRequest(helpRequest);
    }
  }, [helpRequest])


  {/* Handle New Thread Click */ }
  const handleOnClickNewThread = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(setActiveThreadIdBeeBot({ threadId: null }));
    setOutputCode('');
    setInputCode('');
    setSuggestions([]);
    handleQueryStringParamUpdate({ threadId: null });
  }, [])

  {/* Output Text operations */ }

  // Refs to enable smooth scrolling when output is outputted
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null); // Reference for end of messages
  const chatContainerRef = useRef<HTMLDivElement | null>(null); // Reference for chat container


  // Add this function to handle adding prompts to history
  const addToPromptHistory = (prompt: string) => {
    setPromptHistory(prevHistory => {
      // Only add if it's different from the last prompt
      if (prevHistory[0] !== prompt) {
        const newHistory = [prompt, ...prevHistory.slice(0, MAX_HISTORY - 1)];
        return newHistory;
      }
      return prevHistory;
    });
    setHistoryIndex(-1); // Reset index when new prompt is added
  };

  // Add this function to handle keyboard navigation
  const handlePromptNavigation = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

    if ((e.ctrlKey || e.metaKey) && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      e.preventDefault(); // Prevent cursor from moving

      if (promptHistory.length === 0) return;

      setHistoryIndex(prevIndex => {
        let newIndex;
        if (e.key === 'ArrowUp') {
          // Move backwards through history
          newIndex = prevIndex < promptHistory.length - 1 ? prevIndex + 1 : prevIndex;
        } else {
          // Move forwards through history
          newIndex = prevIndex > -1 ? prevIndex - 1 : -1;
        }

        // Update textarea value based on history index
        if (newIndex === -1) {
          if (textareaRef.current) {
            setInputCode('');  // include text in the inputCode
            textareaRef.current.value = ''; // Set the value of the textarea to the inputOnSubmit
            textareaRef.current.focus(); // Focus on the textarea
            textareaRef.current.style.height = 'auto'; // Reset the height to a predefined small height
          }

        } else {
          if (textareaRef.current) {
            setInputCode(promptHistory[newIndex].trim());  // include text in the inputCode
            textareaRef.current.value = promptHistory[newIndex].trim(); // Set the value of the textarea to the inputOnSubmit
            textareaRef.current.focus(); // Focus on the textarea
            textareaRef.current.style.height = 'auto'; // Reset the height to a predefined small height
          }

        }

        return newIndex;
      });
    }
  };

  // smooth scroll while typing, disabled for now, cannot make it work
  // useEffect(() => {

  //   if (loadingState === LoadingState.Streaming) {
  //     // Don't auto-scroll while streaming
  //     return;
  //   }

  //   // Scroll to bottom if user is at the bottom of the chat
  //   if (chatContainerRef.current && endOfMessagesRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
  //     const isAtBottom = scrollHeight - scrollTop === clientHeight;

  //     // Prevent scrolling when typing
  //     if (
  //       isAtBottom &&
  //       !isTyping) {
  //       endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, [messages, outputCode, isTyping]); // Run this effect every time messages change

  // Optional: Add scroll to bottom button
  // const ScrollToBottomButton = () => {
  //   const [showButton, setShowButton] = useState(true);

  //   // Monitor scroll position to show/hide button
  //   useEffect(() => {
  //     const chatContainer = chatContainerRef.current;
  //     if (!chatContainer) return;

  //     // error in how isAtBottom is calculated
  //     const checkScrollPosition = () => {
  //       // const { scrollTop, scrollHeight, clientHeight } = chatContainer;
  //       // console.log('scrollHeight, scrollTop, clientHeight', scrollHeight, scrollTop, clientHeight)
  //       // const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 20;
  //       // setShowButton(!isAtBottom);
  //       setShowButton(true);
  //     };

  //     chatContainer.addEventListener('scroll', checkScrollPosition);
  //     // Also check when new messages arrive
  //     checkScrollPosition();

  //     return () => chatContainer.removeEventListener('scroll', checkScrollPosition);
  //   }, [messages]);

  //   const handleScrollToBottom = () => {
  //     if (endOfMessagesRef.current) {
  //       endOfMessagesRef.current.scrollIntoView({
  //         behavior: 'smooth',
  //         block: 'end'
  //       });
  //     }
  //   };

  //   return showButton ? (
  //     <Tooltip
  //       label="Scroll to bottom"
  //       placement="left"
  //       hasArrow
  //     >
  //       <IconButton
  //         icon={<ArrowDownIcon />}
  //         onClick={handleScrollToBottom}
  //         // position="absolute"
  //         // bottom="20px"
  //         position={'absolute'}
  //         bottom={'calc(100% + 34px)'}
  //         right="20px"
  //         borderRadius="full"
  //         bg="whiteAlpha.200"
  //         _hover={{
  //           bg: "whiteAlpha.300",
  //           transform: "scale(1.1)"
  //         }}
  //         _active={{
  //           bg: "whiteAlpha.400",
  //           transform: "scale(0.95)"
  //         }}
  //         size="lg"
  //         shadow="lg"
  //         aria-label="Scroll to bottom"
  //         transition="all 0.2s"
  //         sx={{
  //           backdropFilter: "blur(8px)",
  //           border: "1px solid",
  //           borderColor: "whiteAlpha.200"
  //         }}
  //         zIndex={1000}
  //       />
  //     </Tooltip>
  //   ) : null;
  // };

  {/* Color palette */ }
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
  const inputColor = useColorModeValue('navy.700', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgIcon = useColorModeValue(
    'linear-gradient(180deg, #FBFBFF 0%, #CACAFF 100%)',
    'whiteAlpha.200',
  );
  const brandColor = useColorModeValue('brand.500', 'white');
  const buttonBg = useColorModeValue('white', 'whiteAlpha.100');
  const gray = useColorModeValue('gray.500', 'white');
  const buttonShadow = useColorModeValue(
    '14px 27px 45px rgba(112, 144, 176, 0.2)',
    'none',
  );
  const textColor = useColorModeValue('navy.700', 'white');
  const placeholderColor = useColorModeValue(
    { color: 'gray.500' },
    { color: 'whiteAlpha.600' },
  );
  const bgColorSuggestions = useColorModeValue(
    'bg-slate-50',
    'bg-slate-800'
  );

  const bgColorSuggestionsHover = useColorModeValue(
    'hover:bg-slate-200',
    'hover:bg-slate-500'
  );

  const bgTextArea = useColorModeValue(
    'white',
    'gray.800'
  );

  const bgColorIcon = useColorModeValue(
    '#f1f5f9',
    '#1e293b'
  )

  {/* Handle pasting of image or file with CLTR + V */ }

  function createFileList(files: File[]): FileList {
    // Create a custom object that mimics the FileList interface
    const fileList: FileList = {
      length: files.length,
      item(index: number): File | null {
        return this[index] || null;
      }
    } as FileList;

    // Add each file to the custom object
    files.forEach((file, index) => {
      (fileList as any)[index] = file;
    });

    return fileList;
  }

  // handle file Drag and Drop
  const handleOnFilesDrop = async (files: File[]) => {

    const imageFiles: File[] = [];
    const textFiles: File[] = [];


    try {
      // Process each dropped file
      for (const file of files) {
        // Handle text files
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
          imageFiles.push(file);
        }
        else {
          textFiles.push(file);
        }
      }
    } catch (error) {
      console.error('Error processing dropped files:', error);
      // Handle error (maybe show a toast notification)
      toast({
        title: 'Error processing files',
        description: 'There was an error processing the dropped files.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }


    if (imageUploaderRef?.current && imageFiles.length > 0) {
      imageUploaderRef.current.uploadFiles(createFileList(imageFiles));
    }
    if (fileUploaderRef?.current && textFiles.length > 0) {
      fileUploaderRef.current.uploadFiles(createFileList(textFiles));
    }

  };

  const handlePaste = async (event: ClipboardEvent<HTMLTextAreaElement>) => {
    const clipboardItems = event.clipboardData.items;

    const imageFiles: File[] = [];
    const textFiles: File[] = [];

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile();
        if (file) {
          imageFiles.push(file);
          // imageUploaderRef.current.uploadFiles([file]);

          // const reader = new FileReader();
          // reader.onload = (event) => {
          //   const imageUrl = event.target?.result as string;
          //   // Handle the image URL (e.g., display it, upload it, etc.)
          //   console.log('Pasted image URL:', imageUrl);
          // };
          // reader.readAsDataURL(blob);
          event.preventDefault(); // Prevent the default paste behavior
        }
      } else if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          // Handle the file (e.g., upload it, display it, etc.)
          // console.log('Pasted file:', file);
          textFiles.push(file);
          event.preventDefault(); // Prevent the default paste behavior
        }
      }
    }
    if (imageUploaderRef?.current && imageFiles.length > 0) {
      imageUploaderRef.current.uploadFiles(createFileList(imageFiles));
    }
    if (fileUploaderRef?.current && textFiles.length > 0) {
      fileUploaderRef.current.uploadFiles(createFileList(textFiles));
    }

  };

  // Handle Delete Images and Files

  const handleDelete = useCallback(async (key: string) => {
    try {
      // setIsDeleting to true to make smooth transition
      setIsDeleting(key);
      // Delete the file from S3
      await Storage.remove(key, { level: 'private' });

      // Remove the image or file from the state - assuming that key is unique, so can run filters on both images and files array
      setImageUrls((prevUrls) => prevUrls.filter((image) => image.key !== key));
      setFileUrls((prevUrls) => prevUrls.filter((file) => file.key !== key));
      // console.log('File deleted successfully:', key);


    } catch (error) {
      console.error('Error deleting file:', error);
      Swal.fire({
        position: 'top',
        toast: true,
        icon: 'error',
        title: `Error deleting file: ${error}`,
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          title: 'font-bold text-red-500', // Tailwind classes for bold and red text
          popup: 'bg-white shadow-lg rounded-md', // Optional: customize the popup background
        }
      })
    } finally {
      // setIsDeleting to false to make smooth transition
      setIsDeleting('');
    }
  }, []);

  interface QueryObject {
    threadId?: string | null,
  }

  // Function to update queryString param update
  const handleQueryStringParamUpdate = (query: QueryObject) => {

    // Create a URLSearchParams object from the existing query string
    const searchParams = new URLSearchParams(location.search);

    // Set the new search query parameter
    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    })

    // Update the URL with the new query parameter
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

  }

  // Function to extract query parameters from url
  const getQueryParams = (query: string): URLSearchParams => {
    return new URLSearchParams(query);
  };
  // useEffect to update query params if url changes
  useEffect(() => {
    // Extract threadId from URL query parameters
    const queryParams = getQueryParams(location.search);
    const threadId = queryParams.get('threadId');
    dispatch(setActiveThreadIdBeeBot({ threadId: threadId || '' }))
  }, [location.search, dispatch]); // Re-run the effect when the URL changes

  // Handle User Submit Message and Run Assistant

  const handleTranslate = async () => {

    // max number of context messages to included in the request
    const MAX_CONTEXT_MESSAGES = 20;
    let idToken = '';
    try {
      // Retrieve the current authenticated user's JWT token
      // const user = await Auth.currentAuthenticatedUser();

      const session = await Auth.currentSession();
      idToken = session.getIdToken().getJwtToken();
      // console.log('session', session)
    } catch (e) {
      console.error('Error checking user login status:', e);
      disclosure.onOpen()
      return;
    }

    // Turnoff the ability to add own API Key for now
    // let apiKey = localStorage.getItem('apiKey');
    // if (!apiKey?.includes('sk-')) {
    //   alert('Please enter an API key.');
    //   return;
    // }

    // Check input is not empty
    if (!inputCode.trim()) {
      handleEmptySubmit();
      return;
    }

    // capture inputCode to inputOnSubmit
    setInputOnSubmit(inputCode);


    // add user and bot message to messages in redux, threadId or default thread
    // Create a new Date object for the current date and time
    const currentDate = new Date();

    // Add 100 milliseconds to the current date and time
    const updatedDate = new Date(currentDate.getTime() + 100);

    dispatch(addMessageBeeBot({
      threadId: activeThreadId || 'default',
      message: {
        role: 'user',
        content: inputCode,
        attachments: [...imageUrls.map(url => url.key), ...fileUrls.map(url => url.key)],
        id: activeThreadId || 'default',
        messageTimestamp: currentDate.toISOString(),
        model: model.id,
      }
    }));

    dispatch(addMessageBeeBot({
      threadId: activeThreadId || 'default',
      message: {
        role: 'assistant',
        content: '',
        attachments: [],
        id: activeThreadId || 'default',
        messageTimestamp: updatedDate.toISOString(),
        model: model.id,
      }
    }));


    // Create a controller that can abort the entire request.
    const newController = new AbortController();
    const signal = newController.signal;
    setController(newController);

    // const body: ChatBody = {
    //   inputCode,
    //   endpoint: ENDPOINTS.OPENAI_CHAT_COMPLETIONS,
    //   model: model?.id,
    //   // Turn off ability to add own API Key
    //   // apiKey,
    //   // add up to the maximum last 10 messages (5 exchanges)
    //   messages: messages.slice(-MAX_CONTEXT_MESSAGES),
    // };

    // use AssistantBody for both Assistant API and ChatCompletions API calls
    const body: AssistantBody = {
      inputCode,
      endpoint: model?.endpoint,
      model: model?.id,
      maxPromptTokens,
      maxCompletionTokens,
      threadId: activeThreadId,
      // conditionally add historical messages if chat completions API is called
      ...model?.endpoint === ENDPOINTS.OPENAI_CHAT_COMPLETIONS ? { messages: messages.slice(-MAX_CONTEXT_MESSAGES) } : {},
      // Turn off ability to add own API Key
      // apiKey,
      ...(imageUrls.length > 0 && { imageUrls }), // Conditionally add imageUrls if it's non-empty
      ...(fileUrls.length > 0 && { fileUrls }) // Conditionally add fileUrls if it's non-empty
    };

    // clear input text and output text in state and TextArea
    if (textareaRef.current) {
      textareaRef.current.value = ''; // Clear the textarea using ref
      // Reset the height to a predefined small height
      textareaRef.current.style.height = 'auto'; // Adjust this based on your initial small height
    }
    // set Loading to True and clear inputs
    setLoadingState(LoadingState.Loading);
    setInputCode(prev => '')
    setOutputCode(prev => '');
    setSuggestions([]);
    setImageUrls([]);
    setFileUrls([]);
    setTokens({});
    setIsFirstVisit(false)
    addToPromptHistory(inputCode.trim());
    // -------------- Fetch --------------
    const urlLambdaFunction = process.env.REACT_APP_BEEBOT_URLLAMBDAFUNCTION || '';

    try {
      console.log('body', body)
      const res = await fetch(urlLambdaFunction, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken, // Add the JWT token to the Authorization header
        },
        signal,
        body: JSON.stringify(body),
      })


      if (!res.ok) {
        Swal.fire({
          position: 'top',
          toast: true,
          icon: 'error',
          title: `Error ${res.status}: ${res.statusText}`,
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            title: 'font-bold text-red-500', // Tailwind classes for bold and red text
            popup: 'bg-white shadow-lg rounded-md', // Optional: customize the popup background
          }
        })
        throw new Error(`HTTP error! Status: ${res.status} - ${res.statusText}`);
      }

      // check endpoint used
      if (model.endpoint === ENDPOINTS.OPENAI_ASSISTANT) {


        // @ts-ignore ReadableStream on different environments can be strange
        const assistantStream = AssistantStream.fromReadableStream(res.body);
        let newThreadId: string = activeThreadId || 'default';

        // Process Stream on MessageDelta
        assistantStream.on('messageDelta', (chunk: any) => {
          // set LoadingState to Streaming, otherwise Abort won't work
          setLoadingState(LoadingState.Streaming);
          // console.log('messageDelta', chunk.content[0].text.value)
          // update setOutput code with the text chunk
          const text = chunk.content?.[0]?.text?.value; // Adjust this based on the format of the chunk

          // deprecated in favor of debounce
          // update the last message content with the text chunk in the threadId
          // dispatch(updateLastMessageBeeBot({ threadId: activeThreadId || newThreadId, newContent: text }));

          // with debounce optimization
          if (text) {
            // Append the text to the message buffer
            messageBufferRef.current += text;
            // Use the debounced update
            debouncedUpdateMessage(
              activeThreadId || newThreadId,
              messageBufferRef.current
            );
          }

        });

        assistantStream.on('runStepDone', (data) => {
          // console.log('runStepDone:', JSON.stringify(data));
        })

        assistantStream.on('end', () => {
          // console.log('Stream ended:');
          setLoadingState(LoadingState.NotLoading);
        });

        assistantStream.on('error', (error) => {
          console.error('Stream error:', error);
          setLoadingState(LoadingState.NotLoading);
        });

        assistantStream.on('run', (data) => {
          // console.log('runData', data)
          setLoadingState(LoadingState.Streaming);
        });

        assistantStream.on('event', (event) => {

          if (event.event === 'thread.created') {
            dispatch(setActiveThreadIdBeeBot({ threadId: event.data.id }))
            handleQueryStringParamUpdate({
              threadId: event.data.id,
            })
            // update user and bot message placeholders with newly generated threadId
            dispatch(updateThreadIdForDefaultMessages({ newThreadId: event.data.id }));

            // Invalidate the threads list so it fetches fresh data
            dispatch(extendedApiSlice.util.invalidateTags([{ type: 'Thread', id: 'LIST' }]));

            //update newThreadId in case useState is out of date
            newThreadId = event.data.id;

          }
        })

        await assistantStream.finalRun().then(({ usage, thread_id, ...props }) => {
          // get the token usage info
          // console.log('usage', usage)
          // console.log('thread_id', thread_id)
          // console.log('props', props)

          // consumption
          if (usage) {
            setTokens(usage);
          }

          // add final complete message to the messages store
          // Bot Message
          // let message = {
          //   role: 'assistant',
          //   content: resultRef.current,
          //   attachments: [],
          //   id: threadIdState,
          //   messageTimestamp: new Date().toISOString(),
          //   model: model.id,
          // }
          // dispatch(addMessageBeeBot({
          //   threadId: thread_id,
          //   message
          // }))
        })

      } else if (model.endpoint === ENDPOINTS.OPENAI_CHAT_COMPLETIONS) {

        // for ChatCompletions API call
        // @ts-ignore ReadableStream on different environments can be strange
        const runner = ChatCompletionStream.fromReadableStream(res.body);
        let newThreadId: string = activeThreadId || 'default';

        // set LoadingState to Streaming, otherwise Abort won't work
        setLoadingState(LoadingState.Streaming);

        runner.on('content', (text, snapshot) => {
          // setOutputCode((prevCode) => prevCode + delta)

          // deprecated in favor of debounce
          // update the last message content with the text chunk in the threadId
          // dispatch(updateLastMessageBeeBot({ threadId: activeThreadId || newThreadId, newContent: delta }));

          // with debounce optimization
          if (text) {
            // Append the text to the message buffer
            messageBufferRef.current += text;
            // Use the debounced update
            debouncedUpdateMessage(
              activeThreadId || newThreadId,
              messageBufferRef.current
            );
          }

        })

        await runner.finalChatCompletion().then((props) => {
          // console.log('chatCompletions', props)
          // get the token usage info
          if (props.usage) {
            setTokens(props.usage);
          }
          // @ts-ignore
          let threadId = props.threadId;

          // add threadId if it doesn't exist
          if (!activeThreadId) {
            dispatch(setActiveThreadIdBeeBot({ threadId }))
            handleQueryStringParamUpdate({
              threadId,
            })
            // update user and bet message placeholders with newly generated threadId
            dispatch(updateThreadIdForDefaultMessages({ newThreadId: threadId }));

            // Invalidate the threads list so it fetches fresh data
            dispatch(extendedApiSlice.util.invalidateTags([{ type: 'Thread', id: 'LIST' }]));

            //update newThreadId in case useState is out of date
            newThreadId = threadId;
          }


          // add final complete message to the messages store
          // let message = {
          //   role: 'assistant',
          //   content: resultRef.current,
          // }
          // dispatch(addMessageBeeBot({ sessionId: 'defaultChat', message }))
        })

        // console.dir(await runner.finalChatCompletion(), { depth: null });
        // setLoading spinner back to false

      }

      setLoadingState(LoadingState.NotLoading);

    } catch (e) {
      console.error('Error processing stream:', e);
    } finally {
      setLoadingState(LoadingState.NotLoading);
    }

  };


  // Function to abort the OpenAI API call
  const abortOpenAICall = () => {
    if (controller) {
      controller.abort();
      setController(null);
      setLoadingState(LoadingState.NotLoading);
    }
  };

  // *** Initializing apiKey with .env.local value
  // useEffect(() => {
  // ENV file verison
  // const apiKeyENV = process.env.NEXT_PUBLIC_OPENAI_API_KEY
  // if (apiKey === undefined || null) {
  //   setApiKey(apiKeyENV)
  // }
  // }, [])


  return (
    <Flex
      // minHeight='90vh'
      w="100%"
      pt={{ base: '70px', md: '0px' }}
      direction="column"
      position="relative"
    >

      <Flex
        // borderColor={'green'}
        // border={"1px"}
        direction="column"
        mx="auto"
        w={{ base: '100%', md: '100%', xl: '100%' }}
        minH={{ base: '75vh', '2xl': '85vh' }}
        maxW="1000px"
      >

        {/* LoginModal - hidden by default */}
        <LoginModal disclosure={disclosure} buttonVisible={false} />

        {/* Main Messages Box */}
        <Flex
          ref={chatContainerRef}
          direction="column"
          flex="1" // Allow the chat container to grow and fill space
          w="100%"
          mx="auto"
          // display={outputCode ? 'flex' : 'none'}
          // mb={'auto'}
          // mb="140px" // Margin bottom to avoid overlap with textarea
          mb={`${suggestionsHeight + 3}px`} // dynamic margin bottom to avoid overlap with textarea
        >
          {/* Message */}
          <Messages editMessage={copyTextToTextarea} />


          <div ref={endOfMessagesRef} />

          {/* Welcome Section - visible only on desktop devices if is new visit */}

          {
            !activeThreadId && !isMobile && isFirstVisit && (
              <WelcomeSection onClick={handleOnFollowUpClick} />
            )
          }


          {/* Suggestions Box - visible only on mobile devices */}
          {isMobile && (
            <Box
              mt={6}
              mb={36}
              ml={14}
              p={3}
              // position="sticky"
              bottom="0"
            // zIndex="10"
            >
              <Flex direction="column" wrap="wrap" justify="left" gap={1} >
                {suggestions.map((suggestion, index) => (
                  <Text
                    key={index}
                    colorScheme="teal"
                    size="sm"
                    width={'70%'}
                    className={`
                      px-3 py-2 text-md cursor-pointer rounded-lg
                      border-2 border-solid
                      // transform transition-all duration-500 ease-in-out translate-y-0 opacity-100
                       hover:${bgColorSuggestionsHover}
                    `}
                    onClick={(event) => handleOnFollowUpClick(event, suggestion)}
                  >
                    {suggestion}
                  </Text>
                ))}
              </Flex>
            </Box>
          )}
        </Flex>



        {/* Chat Input */}
        {/* <Portal> */}

        <DropzoneV2 onFilesDrop={handleOnFilesDrop}>
          <Flex
            position="fixed"
            bottom="20px"
            p="10px 16px"
            backgroundColor={bgTextArea}
            boxShadow="md" // Optional: add a shadow for depth
            right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
            w={{
              base: 'calc(100vw - 8%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 350px)',
              '2xl': 'calc(100vw - 365px)',
            }}
            border="1px solid"
            borderRadius="8px"
            direction="column"
            borderColor={borderColor}
            _focus={{ borderColor: 'none' }}
            zIndex={100}
          >

            {/* <ScrollToBottomButton /> */}


            {/* Follow up suggestions area for large screens*/}
            {!isMobile &&
              (<Flex
                ref={suggestionsRef}
                direction={'row'}
                position={'absolute'}
                bottom={'calc(100% + 3px)'}
                left={'10px'}
                gap={'2px'}
                wrap={'wrap'}
                className="overflow-hidden"
              >
                {suggestions.map((suggestion, index) => (
                  <Text
                    key={index}
                    className={`
                    px-2 py-1 text-sm cursor-pointer rounded-md
                    border-2 border-solid border-slate-300 ${bgColorSuggestions}
                    // transform transition-all duration-500 ease-in-out translate-y-0 opacity-100
                    ${bgColorSuggestionsHover}
                  `}
                    onClick={(event) => handleOnFollowUpClick(event, suggestion)}
                  >
                    {suggestion}
                  </Text>
                ))}
              </Flex>
              )}

            {/*Uploaded Images and Files area */}
            {imageUrls.length > 0 && (
              <Box my={2}>
                {/* <Text>Images uploaded successfully. Public URLs (valid for 1 hour):</Text> */}
                <HStack spacing={4}>
                  {imageUrls.map((image, index) => (
                    <Box
                      key={index}
                      position="relative"
                      transition="opacity 0.3s ease-out, transform 0.3s ease-out"
                      opacity={isDeleting === image.key ? 0 : 1}
                      transform={isDeleting === image.key ? 'scale(0.8)' : 'scale(1)'}
                    >
                      {/* <a href={image.url} target="_blank" rel="noopener noreferrer">
                                    {image.url}
                                </a> */}
                      <Image
                        src={image.url}
                        alt={`Uploaded Thumbnail ${index + 1}`}
                        boxSize="70px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                      <IconButton
                        aria-label="Delete image"
                        icon={<CloseIcon />}
                        size="xs"
                        position="absolute"
                        top="1"
                        right="1"
                        onClick={() => handleDelete(image.key)}
                      />
                    </Box>
                  ))}
                </HStack>
              </Box>
            )}
            {fileUrls.length > 0 && (
              <Box my={2}>
                {/* <Text>Images uploaded successfully. Public URLs (valid for 1 hour):</Text> */}
                <HStack spacing={4}>
                  {fileUrls.map((file, index) => (
                    <Box
                      key={index}
                      position="relative"
                      transition="opacity 0.3s ease-out, transform 0.3s ease-out"
                      opacity={isDeleting === file.key ? 0 : 1}
                      transform={isDeleting === file.key ? 'scale(0.8)' : 'scale(1)'}
                    >
                      {/* <a href={image.url} target="_blank" rel="noopener noreferrer">
                                    {image.url}
                                </a> */}
                      {/* <Image
                      src={file.url}
                      alt={`Uploaded Thumbnail ${index + 1}`}
                      boxSize="70px"
                      objectFit="cover"
                      borderRadius="md"
                    /> */}
                      <Icon as={MdInsertDriveFile} boxSize={6} />
                      <Code>{getFileNameFromS3Key(file.key)}</Code>
                      <IconButton
                        aria-label="Delete file"
                        icon={<CloseIcon />}
                        size="xs"
                        onClick={() => handleDelete(file.key)}
                      />
                    </Box>
                  ))}
                </HStack>
              </Box>
            )}

            {/* Input TextArea */}

            <Textarea
              ref={textareaRef}
              minH="36px" // Initial height. Adjust this to your desired single-line height.
              h="auto" // Use 'auto' to allow the textarea to grow with content
              maxH="250px" // Set a maximum height limitation of 150px
              resize="vertical" // Allows vertical resizing by the user
              onFocus={() => setIsTyping(true)} // prevent scroll when textarea focused and user typing
              onBlur={() => setIsTyping(false)} // enable scroll on blur
              border="0px"
              fontSize={{ base: 'sm', sm: 'md', lg: 'lg' }}
              fontWeight="500"
              // _focus={{ borderColor: 'none' }}
              color={inputColor}
              _placeholder={placeholderColor}
              placeholder="Type your message here or Drag & drop files and images..."
              onChange={(e) => {
                handleChange(e);
                handleInput(); // Call input handler to adjust height
              }}
              onPaste={handlePaste}
              onKeyDown={(e) => {
                handleKeyDown(e);
                handlePromptNavigation(e);
              }} // Add onKeyDown event
              overflowY="auto" // Enable scrolling when overflow occurs
            // disabled={loadingState !== LoadingState.NotLoading} // Disable the textarea when loading
            />

            <Flex
              position="relative"
              pt="4px"
              pb="0px"
              flexDirection={["row", "row", "row"]}
              wrap="wrap"
              alignContent={'space-between'}
              // w={'full'}
              minW={'full'}
            >
              {/* Buttons to upload file, image, select model */}
              <ButtonGroup
                flexDirection={["row", "row", "row"]}
                spacing={[1, 2, 3]}

              >
                <FileUploadButton key={'FileUploadButton'} ref={fileUploaderRef} setFileUrls={setFileUrls} />
                <ImageUploadButton key={'ImageUploadButton'} ref={imageUploaderRef} setImageUrls={setImageUrls} />
                <ModelSelectButton />
                <TokenSettingsButton
                  maxCompletionTokens={maxCompletionTokens}
                  maxPromptTokens={maxPromptTokens}
                  setMaxCompletionTokens={setMaxCompletionTokens}
                  setMaxPromptTokens={setMaxPromptTokens}
                  activeThreadId={activeThreadId}
                />
                <TokenUsageDisplay
                  inputTokens={tokens?.prompt_tokens}
                  outputTokens={tokens?.completion_tokens}
                  isVisible={showTokens}
                  setIsVisible={setShowTokens}
                />
              </ButtonGroup>

              <ButtonGroup
                alignSelf={'self-end'}
                flexDirection={["row", "row", "row"]}
                spacing={[1, 2, 3]}
                ms="auto"
              >
                <Button
                  aria-label="New Thread"
                  variant="primary"
                  // bg={bgColorIcon}
                  alignSelf={'flex-end'}
                  onClick={handleOnClickNewThread}
                >
                  <HStack spacing={{ base: 0, md: 2 }}>
                    <MdOutlineCleaningServices className="text-xl" />
                    <Text
                      display={{ base: 'none', md: 'block' }}
                      fontSize="sm"
                      fontWeight="medium"
                    >
                      New Thread
                    </Text>
                  </HStack>
                </Button>

                <Tooltip label={<> <Kbd bg={'gray.100'}>Ctrl</Kbd> + <Kbd bg={'gray.100'}>Enter</Kbd></>}
                  hasArrow bg='gray.200' color='black' placement='top'
                >

                  <Button
                    variant="primary"
                    py="6px"
                    ps={{ base: '4px', md: '8px' }}
                    fontSize="sm"
                    borderRadius="6px"
                    // borderRadius="45px"
                    ms="auto"
                    w={{ base: '110px', md: '180px', }}
                    h="36px"
                    _hover={{
                      boxShadow:
                        '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                      bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%) !important',
                      _disabled: {
                        bg: 'linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)',
                      },
                    }}
                    onClick={loadingState === LoadingState.Streaming ? abortOpenAICall : handleTranslate}
                    isLoading={loadingState === LoadingState.Loading}
                    loadingText='Generating'
                    spinnerPlacement='end'
                  >
                    {loadingState === LoadingState.Streaming
                      ? (<>
                        Abort
                        <span className='mx-3'>
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="gray.500"
                            size="sm"
                          />
                        </span>
                      </>)
                      : (
                        <>
                          Run <span className={`inline rounded-md shadow-md border border-grey-400 p-1 mx-1 sm:mx-2 bg-[${globalStyles.colors.brand[500]}]`}>Ctrl <MdKeyboardReturn className='before:mx-2 inline' /></span>
                        </>
                      )}
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Flex>
          </Flex>
        </DropzoneV2>
        {/* </Portal> */}

        {/* Chat Input - Footnote comments */}

        {/* 
        <Flex
        position='relative'
          justify="center"
          mt="5px"
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <Text fontSize="xs" textAlign="center" color={gray}>
            Experimental Preview
            ChatGPT may produce inaccurate information
            about people, places, or facts.
      </Text>

      <Link
        to="https://help.openai.com/en/articles/6825453-chatgpt-release-notes"
      >
        <Text
          fontSize="xs"
          color={textColor}
          fontWeight="500"
          textDecoration="underline"
        >
          ChatGPT May 12 Version
        </Text>
      </Link>
    </Flex>
    */}


      </Flex >

    </Flex >

  );
}
