import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {Auth} from '@aws-amplify/auth';


const customHeaders = {
    'Access-Control-Allow-Origin': '*'
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_AWS_DEV_API,
        prepareHeaders: async (headers, {endpoint}) => {
            // TODO: exclude endpoints that do not require Authorization header
            try {
                const token = await Auth.currentSession().then((session) => session.getIdToken().getJwtToken());
                // console.log('token', token)
                if (token) {
                    headers.set('Authorization', token);
                }
            } catch (e) {
                // console.log(e);
            } finally {
                return headers;
            }
        },
    }),
    tagTypes: ['Test', 'Answer', 'Question', 'Session', 'Flashcards', 'Profile', 'Thread', 'Message', 'Feedback'],
    endpoints: builder => ({})
})

