import { useState, useRef } from 'react';
import { DropZone } from '@aws-amplify/ui-react';
import {
    Text,
    Fade,
    Center,
    useDisclosure,
    Box,
    Portal,
    VStack
} from '@chakra-ui/react';


const DropzoneV2 = ({ onFilesDrop, children }) => {

    const [files, setFiles] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleDrop = ({ acceptedFiles, rejectedFiles }) => {
        if (acceptedFiles) {
            setFiles(acceptedFiles);
            if (onFilesDrop) {
                onFilesDrop(acceptedFiles);
            }
            onClose();
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        onOpen();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <>
            <Box
                onDragEnter={handleDragEnter}
                display="inline-block"  // or "block" depending on your needs
                width="fit-content"
                height="fit-content"
                minWidth="min-content"
                flexShrink={0}
            >
                {children}
            </Box>

            {isOpen && (
                <Portal>
                <Fade in={isOpen}>
                    <Center
                        position="fixed"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bg="blackAlpha.600"
                        zIndex="overlay"
                        onDragLeave={handleDragLeave}
                    >

                        <Box
                            position="relative"
                            width="100%"
                            height="100%"
                        >
                            <Box
                                position="absolute"
                                bottom="20px"
                                right={"20px"}
                                width={{ "base": "90%", "md": "85%" }}
                            >
                                <DropZone
                                    onDropComplete={handleDrop}
                                    padding="40px"
                                    borderColor="gray.400"
                                    borderRadius="8px"
                                    borderWidth="1px"
                                    borderStyle="dashed"
                                    backgroundColor="whiteAlpha.100"
                                    style={{
                                        width: '100%',
                                    }}
                                >
                                    <Text
                                        color="black"
                                        fontSize="xl"
                                        textAlign="center"

                                        border={1}
                                    >
                                        Drop files here
                                    </Text>
                                </DropZone>
                            </Box>

                            {files.length > 0 && (
                                <VStack
                                    spacing={2}
                                    color="white"
                                    bg="whiteAlpha.200"
                                    p={4}
                                    borderRadius="md"
                                >
                                    {files.map((file) => (
                                        <Text key={file.name}>
                                            {file.name}
                                        </Text>
                                    ))}
                                </VStack>
                            )}
                        </Box>
                    </Center>
                </Fade>
            </Portal>
            )}
        </>
    );
}

export default DropzoneV2;
