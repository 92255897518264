import { useEffect, useRef } from 'react';

const useEmptyTextareaAnimation = (textareaRef: React.RefObject<HTMLTextAreaElement>) => {
  const animationRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current);
      }
    };
  }, []);

  const handleEmptySubmit = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const isEmpty = textarea.value.trim() === '';

      if (isEmpty) {
        textarea.focus();
        animateTextarea(textarea);
      }
    }
  };

  const animateTextarea = (textarea: HTMLTextAreaElement) => {
    const animationDuration = 500; // Animation duration in milliseconds
    const animationSteps = 5; // Number of animation steps
    const animationDelay = animationDuration / animationSteps;

    const animationKeyframes = [
      { boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)' },
      { boxShadow: 'none' },
    ];

    const animationOptions: KeyframeAnimationOptions = {
      duration: animationDuration,
      iterations: animationSteps * 2 - 1,
      direction: 'alternate', // Use the correct type for direction
    };

    if (animationRef.current) {
      clearTimeout(animationRef.current);
    }

    animationRef.current = setTimeout(() => {
      textarea.animate(animationKeyframes, animationOptions);
    }, animationDelay);
  };

  return handleEmptySubmit;
};

export default useEmptyTextareaAnimation;
