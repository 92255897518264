import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    Icon,
    useBreakpointValue,
    Grid,
    GridItem,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from '@chakra-ui/react';
import {
    MdPublic, // Globe
    MdFlight, // Flight
    MdWarning, // Warning
    MdCode,   // Code
    MdLightbulbOutline, // Lightbulb
    MdPieChart, // Pie Chart
} from 'react-icons/md';

  const CopilotCard = ({ title, description, icon, onClick }) => (
    <Card
      h="100%"
      boxShadow="sm"
      border="1px solid #e2e8f0"
      borderRadius="xl"
      _hover={{
        boxShadow: 'md',
        transform: 'scale(1.02)',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      }}
      onClick={(event) => onClick(event, description)}
    >
      <CardHeader>
        <Flex align="center">
          <Icon as={icon} mr={2} color="blue.500" boxSize="6"/>
          <Heading as="h3" size="sm" fontWeight="medium">
            {title}
          </Heading>
        </Flex>
      </CardHeader>
      <CardBody>
        <Text fontSize="sm" >
          {description}
        </Text>
      </CardBody>
      <CardFooter></CardFooter> {/*Needed for consistent height*/}
    </Card>
  );

const WelcomeSection = ({ onClick }) => {
    const gridColumns = useBreakpointValue({ base: 1, md: 2, lg: 3 });

    const cardsData = [
        {
            title: 'Stand out on socials',
            description: 'What are some tips for writing a great LinkedIn post?',
            icon: MdPublic,
        },
        {
            title: 'Going on holiday?',
            description:
                "Write some funny Out of Office email responses to use while I'm on vacation from [Dec 15-27]",
            icon: MdFlight,
        },
        {
            title: 'Interview warning signs',
            description: 'What are some red flags to watch out for during an interview?',
            icon: MdWarning,
        },
        {
            title: 'Code a binary search in Python',
            description: 'Write a Python script to perform binary search',
            icon: MdCode,
        },
        {
            title: 'Show me the result',
            description: 'Execute and explain this code...',
            icon: MdLightbulbOutline,
        },
        {
            title: 'Graph the data quickly',
            description:
                'Create a pie chart showing the market share of smartphones in the United States',
            icon: MdPieChart,
        },
    ];

    return (
        <Box py={12}>
            <Box maxW="container.xl" mx="auto" px={{ base: 4, md: 8 }}>
                <Heading as="h2" size="2xl" fontWeight="bold" textAlign="center" mb={8}>
                    Bee_Bot
                    <Text as="span" color="blue.500" ml={2}>
                        AI
                    </Text>
                </Heading>
                <Text textAlign="center" mb={10}>Your everyday AI companion</Text>
                <Grid templateColumns={`repeat(${gridColumns}, 1fr)`} gap={6}>
                    {cardsData.map((card, index) => (
                        <GridItem key={index}>
                            <CopilotCard {...card} onClick={onClick} />
                        </GridItem>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default WelcomeSection;