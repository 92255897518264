// @ts-nocheck 
import { memo, useRef, useState } from 'react';
import ReactMarkdown, { Components as ReactMarkdownComponents } from 'react-markdown'
import { useColorModeValue, Flex, Icon, Text, Box, HStack, IconButton, Code, Avatar } from '@chakra-ui/react'
import Card from '../card/Card'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkLintListItemIndent from 'remark-lint-list-item-indent';
import CodeBlock from '../../../CodeBlock';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css'; // Import the KaTeX CSS
import { Message } from '../../types/types';
import { CloseIcon } from '@chakra-ui/icons';
import {
    MdAutoAwesome, MdBolt, MdEdit, MdPerson,
    MdKeyboardReturn, MdOutlineAttachFile, MdOutlineInsertPhoto, MdClear,
    MdOutlineCleaningServices, MdInsertDriveFile, MdPause, MdPlayArrow
} from 'react-icons/md';
import MessageBox from '../messageBox/MessageBox';
import { globalStyles } from '../../theme/styles';
import { getFileNameFromS3Key } from '../../utils/otherUtils';
import { ButtonGroup } from 'semantic-ui-react';
import { useGetProfileQuery } from '../../../../store/testsSlice';

interface CodeProps extends React.HTMLProps<HTMLDivElement> {
    node?: any; // Define according to the actual structure of the node
    inline?: boolean;
    className?: string;
    children: React.ReactNode; // This allows any valid React nodes to be passed as children
}

interface InlineMathProps {
    value: string; // Assuming the math value will be a string
}

interface BlockMathProps {
    value: string; // Similarly assuming for block math
}

interface UserMessageBoxProps {
    message: Message;
    editMessage: (message: Message) => void;
}

function arePropsEqual(oldProps, newProps) {

    return (
        oldProps.message.id === newProps.message.id &&
        oldProps.message.messageTimestamp === newProps.message.messageTimestamp
    );
}

const UserMessageBox = memo(function UserMessageBox({ message, editMessage }: UserMessageBoxProps) {
    const { content, role, attachments } = message

    const { data, isSuccess, error, isLoading, isFetching } = useGetProfileQuery('DEFAULT');

    {/* Color palette */ }
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.200');
    const inputColor = useColorModeValue('navy.700', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgIcon = useColorModeValue(
        'linear-gradient(180deg, #FBFBFF 0%, #CACAFF 100%)',
        'whiteAlpha.200',
    );
    const brandColor = useColorModeValue('brand.500', 'white');
    const buttonBg = useColorModeValue('white', 'whiteAlpha.100');
    const gray = useColorModeValue('gray.500', 'white');
    const buttonShadow = useColorModeValue(
        '14px 27px 45px rgba(112, 144, 176, 0.2)',
        'none',
    );
    const textColor = useColorModeValue('navy.700', 'white');
    const placeholderColor = useColorModeValue(
        { color: 'gray.500' },
        { color: 'whiteAlpha.600' },
    );

    const bgColor = useColorModeValue(
        globalStyles.colors.secondaryGray[100],
        globalStyles.colors.secondaryGray[900]
    )


    return (
        < Flex w="100%" align={'center'} mb="5px" >
            <Flex
                borderRadius="full"
                justify="center"
                align="center"
                bg={'transparent'}
                border="1px solid"
                borderColor={borderColor}
                // me="20px"
                h="40px"
                minH="40px"
                minW="40px"
            >
                {
                    isSuccess && data?.photo
                        ? (<Avatar src={data?.photo} />)
                        : (<Icon
                            as={MdPerson}
                            width="20px"
                            height="20px"
                            color={brandColor}
                        />)
                }
            </Flex>
            <Flex
                px="22px"
                py="4px"
                borderColor={borderColor}
                borderRadius="14px"
                w="100%"
            >
                <Text
                    color={textColor}
                    fontWeight="600"
                    fontSize={{ base: 'sm', md: 'md' }}
                    lineHeight={{ base: '24px', md: '26px' }}
                >
                    <MessageBox message={message} bg={bgColor} />
                    {attachments?.length > 0 && (
                        <Box my={2}>
                            <HStack spacing={4}>
                                {attachments.map((fileKey: string, index: number) => (
                                    <Box
                                        key={index}
                                        position="relative"
                                        transition="opacity 0.3s ease-out, transform 0.3s ease-out"
                                        opacity={1}
                                        transform={'scale(1)'}
                                        border={'1px'}
                                        borderColor={borderColor}
                                        rounded={10}
                                        px={1}
                                    >
                                        <Icon as={MdInsertDriveFile} boxSize={6} />
                                        <Code>{getFileNameFromS3Key(fileKey)}</Code>
                                        {/* hard problem: need to delete in S3 bucket, OpenAI API, and DynamoDB chat history table
                                        <IconButton
                                            aria-label="Delete file"
                                            icon={<CloseIcon />}
                                            size="xs"
                                            // onClick={() => handleDelete(fileKey)}
                                        /> */}
                                    </Box>
                                ))}
                            </HStack>
                        </Box>
                    )}
                </Text>

                <IconButton
                    aria-label={"Edit Message"}
                    icon={<MdEdit />}
                    onClick={() => editMessage(message)}
                    ms="auto"
                    width="30px"
                    height="30px"
                    color={gray}
                    border={'1px'}
                    borderColor={"#f3f4f6"}
                />

            </Flex>
        </Flex>
    )
}, arePropsEqual)


export default UserMessageBox;