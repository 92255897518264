import React from 'react'
import Link from '../../components/link/Link';
import MessageBoxChat from '../../components/messageBox/MessageBox';
import { ChatBody, OpenAIModel } from '../../types/types';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Heading,
    Icon,
    Img,
    Input,
    MenuItem,
    Text,
    Tooltip,
    useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { MdAutoAwesome, MdBolt, MdEdit, MdPerson } from 'react-icons/md';
import Bg from '../../../images/BeeBot/chat/bg-image.png';
import { ChatCompletionStream } from 'openai/lib/ChatCompletionStream';
import { Auth } from 'aws-amplify';
import { SSE } from 'sse.js';
import { globalStyles } from '../../theme/styles';
import { selectBeeBotModel } from '../../../../store/store';
import { setModelBeeBot } from '../../../../store/beeBotSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAvailableModels } from '../../../../constants/aiModels';
import { formatPriceUSD } from '../../../../utils/formatPrice';
import ModelDisplay from '../modelDisplay/ModelDisplay';

const ModelChange = ({ onClose }: { onClose: () => void }) => {

    // ChatGPT model
    // remove local state and use redux store instead
    // const [model, setModel] = useState<OpenAIModel>();

    const dispatch = useDispatch();
    const currentModel = useSelector(selectBeeBotModel);

    const handleOnClickSelectModel = (model: OpenAIModel): void => {
        if (model) {
            // redux store update
            onClose();
            dispatch(setModelBeeBot(model));
            // local component state update
            // setModel(model);
        }
    }

    const availableModels = getAvailableModels();

    // load model from the redux store or set default
    useEffect(() => {
        if (!currentModel) {
            dispatch(setModelBeeBot(availableModels[0]))
            // setModel(prev => currentModel ? model : availableModels[0])
        }
        //  else {
        //     setModel(prev => currentModel)
        // }
    }, [currentModel])

    const iconColor = useColorModeValue('brand.500', 'white');
    const bgIcon = useColorModeValue(
        'linear-gradient(180deg, #FBFBFF 0%, #CACAFF 100%)',
        'whiteAlpha.200',
    );
    const buttonBg = useColorModeValue('white', 'whiteAlpha.100');
    const buttonShadow = useColorModeValue(
        '14px 27px 45px rgba(112, 144, 176, 0.2)',
        'none',
    );
    const textColor = useColorModeValue('navy.700', 'white');

    {/* Model Change */ }
    return (
        // < Flex direction={'column'} w="100%" mb={'auto'} >
        <Flex
            mx="auto"
            zIndex="2"
            w="max-content"
            mb="10px"
            borderRadius="60px"
            direction={'column'}
        >
            <Text fontWeight="500" fontSize="sm">
                Select Model:
            </Text>
            {
                availableModels.map((model: OpenAIModel) =>
                (
                    // Use of MenuItem to leverage onClose functionality
                    <MenuItem
                        background="transparent" // Make background fully transparent
                        _hover={{ background: 'transparent' }} // Keep transparent on hover
                        _focus={{ background: 'transparent' }} // Keep transparent on focus
                        _active={{ background: 'transparent' }} // Keep transparent when active
                        border="none" // Remove any borders
                        padding={0} // Remove padding to prevent unwanted spacing
                        key={model.id}
                    >
                        <Tooltip
                            label={[
                                `${model.description}. `,
                                `Price Input: ${formatPriceUSD(model.inputPrice)} / 1M tokens |`,
                                `Price Output: ${formatPriceUSD(model.outputPrice)} / 1M tokens`,
                            ].join('\n')
                            }
                            placement="right"
                            hasArrow
                            bg="gray.700"
                            color="white"
                            borderRadius="md"
                            px="3"
                            py="2"
                        >
                            <Flex
                                key={model.id}
                                cursor={'pointer'}
                                transition="0.3s"
                                justify={'start'}
                                align="center"
                                p={2}
                                bg={model?.id === currentModel?.id ? buttonBg : 'transparent'}
                                w="162px"
                                h="50px"
                                boxShadow={model?.id === currentModel?.id ? buttonShadow : 'none'}
                                borderRadius="14px"
                                color={textColor}
                                fontSize="16px"
                                fontWeight={'700'}
                                border={model?.id === currentModel?.id ? '2px solid #4A90E2' : '2px solid transparent'} // Border color when selected
                                _hover={{
                                    border: model?.id === currentModel?.id ? '2px solid #4A90E2' : '2px solid #A1B2C3', // Lighter border color on hover, change color as needed
                                }}
                                onClick={() => handleOnClickSelectModel(model)}
                            >
                                <Flex
                                    borderRadius="full"
                                    justify="center"
                                    align="center"
                                    bg={bgIcon}
                                    me="10px"
                                    h="30px"
                                    w="30px"
                                >
                                    <Icon
                                        as={MdAutoAwesome}
                                        width="15px"
                                        height="15px"
                                        color={iconColor}
                                    />
                                </Flex>
                                <ModelDisplay
                                    displayText={model.displayText}
                                    displayBadge={model.displayBadge}
                                    displayBadgeStyle={model.displayBadgeStyle}
                                />
                            </Flex>
                        </Tooltip>
                    </MenuItem>

                ))
            }
        </Flex >
    )
    {/* Plugins */ }
    {/*
     <Accordion color={gray} allowToggle w="100%" my="0px" mx="auto">
       <AccordionItem border="none">
         <AccordionButton
           borderBottom="0px solid"
           maxW="max-content"
           mx="auto"
           _hover={{ border: '0px solid', bg: 'none' }}
           _focus={{ border: '0px solid', bg: 'none' }}
         >
           <Box flex="1" textAlign="left">
             <Text color={gray} fontWeight="500" fontSize="sm">
               No plugins added
             </Text>
           </Box>
           <AccordionIcon color={gray} />
         </AccordionButton>
         <AccordionPanel mx="auto" w="max-content" p="0px 0px 10px 0px">
           <Text
             color={gray}
             fontWeight="500"
             fontSize="sm"
             textAlign={'center'}
           >
             This is a cool text example.
           </Text>
         </AccordionPanel>
       </AccordionItem>
     </Accordion>
     */}
    // </Flex >
    // )
}

export default ModelChange