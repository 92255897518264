import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  useBreakpointValue,
  ButtonGroup,
  useColorModeValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import heroSectionImage from '../../images/hero_section_image.png'

// use https://exactly.ai/ as the model for landing page

const HeroV2 = () => {
  const imageSize = useBreakpointValue({ base: '100%', md: '50%' });
  const containerPadding = useBreakpointValue({ base: '20px', md: '40px' });
  const headingSize = useBreakpointValue({ base: '2xl', md: '4xl' });
  const textSize = useBreakpointValue({ base: 'md', md: 'lg' });
  const maxWidth = useBreakpointValue({ base: '100%', md: '1800px' });
  const backgroundColor = useColorModeValue('#f7f7f7', 'gray.800'); // Background color


  const navigate = useNavigate();



  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      p={containerPadding}
      bg={backgroundColor} // Match background color
      maxW={maxWidth}
      margin={'0 auto'}
    >
      <Box w={{ base: '100%', md: '50%' }} pr={{ md: 8 }}>
        <Heading as="h1" size={headingSize} fontWeight="bold" mb={4}>
          bespoke ai apps, <br /> built for you
        </Heading>
        <Text fontSize={textSize} mb={6}>
          Turbocharge your work and study with helpful AI apps, powered by leading AI models.<br />
          We make AI tools accessible and affordable to everyone. Pay only for what you use, with free credits monthly.

        </Text>
        <ButtonGroup spacing={4} mb={6}>
          <Button
            // colorScheme={"#20B486"} // Match button color
            // _hover={{ bg: 'orange.700' }} // Match hover color
            // variant="brand"
            size="md"
            rounded="full"
            bg="#FF5733" // Use the orange color
            color="white"
            _hover={{ bg: "#E04A2B" }}
            _active={{ bg: "#C13D1D" }}
            onClick={() => navigate('/beebot')}
          >
            Try for free
          </Button>
          <Button
            size="md"
            rounded={'full'}
            variant="outline"
            disabled={true}
            cursor={'not-allowed'}
          // onClick={() => {
          //   window.open('XXXXXXXXXXXXXXXXXXXXXXXXXXXXX', '_blank');
          // }}
          >
            Sign-Up
          </Button>
        </ButtonGroup>
      </Box>
      <Box w={imageSize}>
        <Image
          src={heroSectionImage} // Replace with your image URL
          alt="Bespoke AI Apps"
          borderRadius="md" // Add slight border radius (optional)
          objectFit="cover" // Ensure image covers the box
        />
      </Box>
    </Flex>
  );
};

export default HeroV2;