import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    model: null,
    threads: {},
    activeThreadId: null, // Add activeThreadId to the initial state,
};

const beePrepSlice = createSlice({
    name: 'beePrep',
    initialState,
    reducers: {
        setModelBeePrep: (state, action) => {
            state.model = action.payload;
        },
        setThreadIdBeePrep: (state, action) => {
            const { threadId } = action.payload;
            state.threads[threadId] = {
                ...state.threads[threadId],
                threadId,
            };
        },
        addQuestionsBeePrepFromAPI: (state, action) => {
            const { threadId, questions } = action.payload;
            state.threads[threadId] = {
                ...state.threads[threadId],
                questions: [
                    ...(state.threads[threadId]?.questions || []),
                    ...questions,
                ],
            };
        },
        // updateLastMessageBeeBot: (state, action) => {
        //     const { threadId, newContent } = action.payload;
        //     const thread = state.threads[threadId];
        //     if (thread?.messages?.length > 0) {
        //         const lastIndex = thread.messages.length - 1;
        //         thread.messages[lastIndex] = {
        //             ...thread.messages[lastIndex],
        //             content: thread.messages[lastIndex].content + newContent,
        //         };
        //     }
        // },
        // updateThreadIdForDefaultMessages: (state, action) => {
        //     const { newThreadId } = action.payload;
        //     const defaultThread = state.threads['default'];
        //     if (defaultThread?.messages) {
        //         const updatedMessages = defaultThread.messages.map(message => ({
        //             ...message,
        //             id: newThreadId,
        //         }));
        //         state.threads[newThreadId] = {
        //             ...defaultThread,
        //             messages: [
        //                 ...(state.threads[newThreadId]?.messages || []),
        //                 ...updatedMessages,
        //             ],
        //         };
        //         delete state.threads['default'];
        //     }
        // },
        // New reducer to update activeThreadId
        setActiveThreadIdBeePrep: (state, action) => {
            state.activeThreadId = action.payload.threadId;
        },

        // Add questions to the existing threadId from TestAIUsage
        addQuestionsBeePrepFromDB: (state, action) => {
            const { threadId, questions, lastEvaluatedKey } = action.payload;
            if (!state.threads[threadId]) {
                state.threads[threadId] = {};
                state.threads[threadId]['questions'] = [];
            }
            state.threads[threadId]['questions'] = [...state.threads[threadId]['questions'], ...questions];
            state.threads[threadId]['lastEvaluatedKey'] = lastEvaluatedKey;
        },
        logoutBeePrep: (state, action) => {
            return initialState;
        },

        // Add formData to the threadId
        addFormDataBeePrep: (state, action) => {
            const { threadId, formData } = action.payload;
            state.threads[threadId] = {
                ...state.threads[threadId],
                formData,
            };
        },
    },
});

export const { setModelBeePrep, setThreadIdBeePrep, addQuestionsBeePrepFromAPI,
    setActiveThreadIdBeePrep,
    addQuestionsBeePrepFromDB, logoutBeePrep, addFormDataBeePrep } = beePrepSlice.actions;
export default beePrepSlice.reducer;


// const state = {
//     threads : {
//         'threadId 1' : {
//             threadId: 'threadId 1',
//             questions: [{q1}, {q2}],
//             lastEvaluatedKey: 'lastEvaluatedKey',
//             metaData: formData,
//         }
//     }
// }