import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  VStack,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Button,
  Container,
  Fade,
  Progress,
  HStack,
  Badge,
  CloseButton,
  useToast,
  Icon,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Spinner,
  CheckboxGroup,
  Checkbox
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { addQuestionsBeePrepFromDB } from '../../store/beePrepSlice';
import { useGetBeePrepQuestionsByThreadIdQuery } from '../../store/testsSlice';
import { selectBeePrepFormDataByThreadId, selectBeePrepQuestionsByThreadId } from '../../store/store';
import { ArrowRightIcon } from '@chakra-ui/icons';
import QuestionNavigation from './QuestionNavigation';
import { usePostBeePrepGenerateQuestionsMutation } from '../../store/testsSlice';
import { addFormDataBeePrep, addQuestionsBeePrepFromAPI } from '../../store/beePrepSlice';
import MenuDropdown from './MenuDropdown';
import TestSummary from './TestSummary';
import { MdAutoAwesome, MdOutlineFlag } from 'react-icons/md';
import BeeBot from '../BeeBot/app/BeeBot';


const PracticeTest = () => {
  const { threadId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showInfoBar, setShowInfoBar] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isTestComplete, setIsTestComplete] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const chatBoxRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();



  // API call to generate BeePrep practice questions
  const [generatePracticeQuestions, { data: genQuestionsData, isLoading: genQuestionsDataIsLoading, isError: genQuestionsIsError, isSuccess: genQuestionsIsSuccess, error: genQuestionsError }] = usePostBeePrepGenerateQuestionsMutation();
  const questions = useSelector(state => selectBeePrepQuestionsByThreadId(state, threadId));
  let formData = useSelector(state => selectBeePrepFormDataByThreadId(state, threadId));

  const { data, error, isLoading } = useGetBeePrepQuestionsByThreadIdQuery({ threadId }, {
    skip: questions.length > 0, // Skip the query if we have local questions
  });

  useEffect(() => {
    if (data && questions.length === 0) {
      // Dispatch the action to add the questions from DB API call in the Redux store
      dispatch(addQuestionsBeePrepFromDB(data))
      // dispatch(setQuestions({ testId, questions: data.questions }));
    }
  }, [data, questions, dispatch, threadId]);

  // AI Chatbot
  useEffect(() => {
    if (isChatOpen && chatBoxRef.current) {
      chatBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isChatOpen]);


  const handleAnswerSelectMultipleChoice = (questionId, index) => {
    // onChange triggered only when n a different answer is selected
    // means that user cannot unselect selection once clicked
    setSelectedAnswers(prev => ({ ...prev, [questionId]: [index] }));
  };

  // not required for now, CheckboxGroup already encapsulates the logic
  const handleAnswerSelectMultipleAnswer = (questionId, answerIndex) => {
    setSelectedAnswers(prev => {
      const currentAnswers = prev[questionId] || [];
      let updatedAnswers;

      if (currentAnswers.includes(answerIndex)) {
        // If the answer is already selected, remove it
        updatedAnswers = currentAnswers.filter(index => index !== answerIndex);
      } else {
        // If the answer is not selected, add it
        updatedAnswers = [...currentAnswers, answerIndex];
      }

      // Sort the indices to maintain consistent order
      updatedAnswers.sort((a, b) => a - b);

      return { ...prev, [questionId]: updatedAnswers };
    });
  };


  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
      setShowAnswer(false);
    } else {
      generateMoreQuestionsIfNeeded()
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
      setShowAnswer(false);
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={10}>
        <Heading mb={2} size={'md'}>Loading your practice test...</Heading>
        <Spinner size={'sm'} m={2} />
      </Box>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  // console.log('currentQuestion', currentQuestion)

  // Helper function to determine badge color based on difficulty
  const getDifficultyColor = (difficulty) => {
    switch (difficulty?.toLowerCase()) {
      case 'beginner':
        return 'green';
      case 'intermediate':
        return 'orange';
      case 'advanced':
        return 'red';
      default:
        return 'gray';
    }
  };

  const handleQuestionSelect = (index) => {
    setCurrentQuestionIndex(index);
  };

  function compareArrays(arr1, arr2) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Compare each element
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    // If we've made it this far, the arrays are equal
    return true;
  }

  const generateMoreQuestionsIfNeeded = async () => {
    if (currentQuestionIndex === questions.length - 1) {
      try {

        if (!formData) {
          // get formData from the current question
          formData = questions[currentQuestionIndex]?.metaData;
          // console.log('formData updated:', formData)
        }

        if (!formData) {
          console.error('No form data found for generating more questions');
          toast({
            title: "Error",
            description: "Failed to generate practice questions, Form Data is missing",
            status: "error",
            duration: 3000,
            isClosable: true,
            // Add these properties for white text
            // color: "white",
          });
          return;
        }

        const response = await generatePracticeQuestions({ threadId, formData }).unwrap();
        // console.log('threadId:', threadId)
        // console.log('formData:', formData)
        // console.log('Generated more questions:', response);
        dispatch(addQuestionsBeePrepFromAPI(response));

        // add formData to threadId
        dispatch(addFormDataBeePrep({ threadId, formData }));
        // Optionally, you can update the local state as well
        // setQuestions(prevQuestions => [...prevQuestions, ...newQuestions]);
      } catch (error) {
        console.error('Failed to generate more questions:', error);
        // Handle the error (e.g., show a notification to the user)
      }
    }
  };

  // calculate results function
  const calculateResults = () => {
    const totalQuestions = questions.length;
    let correct = 0;
    let incorrect = 0;
    let skipped = 0;

    questions.forEach((question, index) => {
      const userAnswers = selectedAnswers[question.id] || [];
      if (userAnswers.length === 0) {
        skipped++;
      } else if (compareArrays(userAnswers, question.correctAnswersIndex)) {
        correct++;
      } else {
        incorrect++;
      }
    });

    return {
      totalQuestions,
      correct,
      incorrect,
      skipped,
      correctPercentage: (correct / totalQuestions) * 100,
      incorrectPercentage: (incorrect / totalQuestions) * 100,
      skippedPercentage: (skipped / totalQuestions) * 100,
    };
  };

  const handleCompleteTest = () => {
    setIsTestComplete(true);
    setShowResults(true);
  };



  return (
    <Fade in={questions.length > 0}>
      <Container
        // maxW="container.md"
        maxW={{ base: '100%', md: '75%', lg: '60%', xl: '50%' }}
        py={10}>
        <VStack spacing={4} align="stretch">
          <Heading size="md" textAlign="center" mb={2}>
            <Icon viewBox="0 0 24 24" boxSize={5} mr={'2'}>
              <path
                fill="currentColor"
                d="M7.5,5.6L5,7L6.4,4.5L5,2L7.5,3.4L10,2L8.6,4.5L10,7L7.5,5.6M19.5,15.4L22,14L20.6,16.5L22,19L19.5,17.6L17,19L18.4,16.5L17,14L19.5,15.4M22,2L20.6,4.5L22,7L19.5,5.6L17,7L18.4,4.5L17,2L19.5,3.4L22,2M13.34,12.78L15.78,10.34L13.66,8.22L11.22,10.66L13.34,12.78M14.37,7.29L16.71,9.63C17.1,10 17.1,10.65 16.71,11.04L5.04,22.71C4.65,23.1 4,23.1 3.63,22.71L1.29,20.37C0.9,20 0.9,19.35 1.29,18.96L12.96,7.29C13.35,6.9 14,6.9 14.37,7.29Z"
              />
            </Icon>
            {questions?.[currentQuestionIndex]?.examinationName ?? 'AI Generated Practice Test'}
          </Heading>
          <Progress
            width={'full'}
            value={((currentQuestionIndex + 1) / questions.length) * 100}
            size="md"
            colorScheme="teal"
          />

          {/* QuestionNavigation component */}
          <QuestionNavigation
            questions={questions}
            selectedAnswers={selectedAnswers}
            currentQuestionIndex={currentQuestionIndex}
            onQuestionSelect={handleQuestionSelect}
          />

          <Box borderWidth={1} borderRadius="lg" p={6} boxShadow="md">
            <Text fontWeight="bold" mb={4}>
              Question {currentQuestionIndex + 1} of {questions.length}
            </Text>

            {/* Info bar with difficulty and topic */}

            {!showInfoBar && (
              <IconButton
                icon={<ArrowRightIcon />}
                aria-label="Show question info"
                size="sm"
                onClick={() => setShowInfoBar(true)}
              />
            )}

            {showInfoBar && (
              <Box
                mb={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <HStack spacing={2} mb={2} overflow={'hidden'} wrap={'wrap'}>
                  <Badge
                    colorScheme={getDifficultyColor(currentQuestion?.questionDifficulty)}
                  >
                    {currentQuestion?.questionDifficulty}
                  </Badge>
                  <Badge colorScheme="purple">{currentQuestion?.examinationTopic}</Badge>
                </HStack>
                <CloseButton onClick={() => setShowInfoBar(false)} />
              </Box>
            )}

            <Text mb={4} fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}>{currentQuestion?.questionText}</Text>

            {currentQuestion?.['questionType'] === 'multiple_choice' &&
              <RadioGroup
                // only onChange method works with index, onClick gives synthetic event
                onChange={(index) => handleAnswerSelectMultipleChoice(currentQuestion?.id, parseInt(index, 10))}
                value={selectedAnswers[currentQuestion?.id]?.[0]}
              >
                <VStack
                  align="stretch"
                  spacing={{ base: '1', md: '2', lg: '3' }}
                >
                  {currentQuestion?.options?.map((option, index) => {
                    const letter = String.fromCharCode(65 + index); // 65 is ASCII for 'A'
                    return (
                      <Radio
                        size={{ base: 'sm', md: 'md', lg: 'lg' }}
                        key={index}
                        value={index}
                        spacing='1.25rem'
                        bg={showAnswer
                          ? (currentQuestion?.correctAnswersIndex.includes(index)
                            ? 'green.100'
                            : selectedAnswers[currentQuestion?.id]?.includes(index)
                              ? 'red.100'
                              : 'transparent')
                          : 'transparent'
                        }
                        borderColor={showAnswer
                          ? (currentQuestion?.correctAnswersIndex.includes(index)
                            ? 'green.400'
                            : selectedAnswers[currentQuestion?.id]?.includes(index)
                              ? 'red.400'
                              : 'gray.200')
                          : 'gray.200'
                        }
                        p={2}
                      >
                        <HStack alignItems="center" spacing={2}>
                          <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}>
                            {letter}.
                          </Text>
                          <Text
                            mb={4}
                            fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}
                            bg={showAnswer
                              ? (currentQuestion?.correctAnswersIndex.includes(index)
                                ? 'green.100'
                                : selectedAnswers[currentQuestion?.id]?.includes(index)
                                  ? 'red.100'
                                  : 'transparent')
                              : 'transparent'
                            }
                            py={1}
                            px={2}
                            // borderWidth={2}
                            borderRadius="lg"
                          >
                            {option?.optionText}
                          </Text>
                        </HStack>
                      </Radio>
                    )
                  })}


                </VStack>
              </RadioGroup>
            }


            {currentQuestion?.['questionType'] === 'multiple_answer' &&
              <CheckboxGroup
                // CheckboxGroup needs to maintain state array in letters, otherwise getting erros on 0 element
                // but selectedAnswers state requires numbers
                // so making conversation from numbers to letters for CheckboxGroup, to confine the issue to this component
                value={selectedAnswers[currentQuestion?.id]?.map(val => `${val}`) ?? []}
                onChange={(values) => {
                  values = values.map(val => parseInt(val, 10)).sort((a, b) => a - b);
                  setSelectedAnswers(prev => ({ ...prev, [currentQuestion?.id]: values }));
                }}
              >
                <VStack align="stretch" spacing={{ base: '1', md: '2', lg: '3' }}>
                  {currentQuestion?.options?.map((option, index) => {
                    const letter = String.fromCharCode(65 + index);
                    return (
                      <Checkbox
                        size={{ base: 'md', md: 'lg', lg: 'lg' }}
                        key={index}
                        value={index.toString()}
                        spacing='1.25rem'
                        bg={showAnswer
                          ? (currentQuestion?.correctAnswersIndex.includes(index)
                            ? 'green.100'
                            : selectedAnswers[currentQuestion?.id]?.includes(index)
                              ? 'red.100'
                              : 'transparent')
                          : 'transparent'
                        }
                        borderColor={
                          showAnswer
                            ? (currentQuestion?.correctAnswersIndex.includes(index)
                              ? 'green.400'
                              : selectedAnswers[currentQuestion?.id]?.includes(index)
                                ? 'red.400'
                                : 'gray.200')
                            : 'gray.200'}
                        p={2}
                      >
                        <HStack alignItems="center" spacing={2}>
                          <Text fontWeight="bold" fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}>
                            {letter}.
                          </Text>
                          <Text
                            mb={4}
                            fontSize={{ base: 'sm', md: 'md', lg: 'lg', xl: 'xl' }}
                            bg={showAnswer
                              ? (currentQuestion?.correctAnswersIndex.includes(index)
                                ? 'green.100'
                                : selectedAnswers[currentQuestion?.id]?.includes(index)
                                  ? 'red.100'
                                  : 'transparent')
                              : 'transparent'
                            }
                            py={1}
                            px={2}
                            borderRadius="lg"
                          >
                            {option?.optionText}
                          </Text>
                        </HStack>
                      </Checkbox>
                    );
                  })}
                </VStack>
              </CheckboxGroup>
            }


            <Box mt={4}>
              <Button
                onClick={() => setShowAnswer(!showAnswer)}
                colorScheme="blue"
                mb={4}
              // leftIcon={<RepeatIcon />}
              >
                <Box display={{ base: 'block' }}>
                  {showAnswer ? "Hide Answer" : "Show Answer"}
                </Box>
              </Button>

              {showAnswer && (
                <Box
                  borderWidth={1}
                  borderRadius="lg"
                  p={4}
                  bg="gray.50"
                  boxShadow="md"
                >
                  <Text fontWeight="bold" mb={2}>
                    Explanation:
                  </Text>
                  <Text mb={4} whiteSpace="pre-wrap">{currentQuestion?.explanation}</Text>

                  <Text fontWeight="bold" mb={2}>
                    Correct Answer{currentQuestion?.correctAnswersIndex?.length > 1 ? 's' : ''}:
                  </Text>
                  {currentQuestion?.correctAnswersIndex.map((index) => (
                    <Text
                      key={index}
                      color="green.600"
                      fontWeight="semibold"
                    >
                      {String.fromCharCode(65 + index)}. {currentQuestion.options?.[index]?.optionText}
                    </Text>
                  ))}

                  <Text fontWeight="bold" mt={4} mb={2}>
                    Your Answer:
                  </Text>
                  {selectedAnswers[currentQuestion.id]?.map((index) => (
                    <Text
                      key={index}
                      color={currentQuestion?.correctAnswersIndex.includes(index) ? "green.600" : "red.600"}
                      fontWeight="semibold"
                    >
                      {String.fromCharCode(65 + index)}. {currentQuestion.options?.[index]?.optionText}
                    </Text>
                  ))}
                </Box>
              )}
            </Box>


          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <Button
              size={{ base: 'sm', lg: 'md' }}
              onClick={handlePreviousQuestion}
              isDisabled={currentQuestionIndex === 0}
              textColor={'teal'}
              fontWeight={'bold'}
            // leftIcon={<ArrowLeftIcon />}
            >
              <Box display={{
                base: 'block',
                //  md: 'block' 
              }}>Previous</Box>
            </Button>
            {/* Generate More Questions Button */}
            <ButtonGroup size={{ base: 'sm', lg: 'md' }} isAttached variant="outline" spacing={0}>
              <Button
                leftIcon={<MdOutlineFlag />}
                colorScheme="teal"
                onClick={handleCompleteTest}
                // isDisabled={isTestComplete}
                variant={'outline'}
              // mt={4}
              >
                <Box display={{
                  base: 'none',
                  md: 'block'
                }}
                >
                  <Box display={{
                    base: 'none',
                    md: 'block'
                  }}
                  >
                    Complete Test
                  </Box>
                </Box>
              </Button>
              <MenuDropdown isGenerateNew={false} existingFormState={formData} />

              <Button
                leftIcon={
                  <Icon
                    as={MdAutoAwesome}
                    width="15px"
                    height="15px"
                  // color={iconColor}
                  />
                }
                variant="primary"
                onClick={() => {
                  onOpen();
                  setIsChatOpen(true);
                }}
              >
                <Box display={{
                  base: 'none',
                  md: 'block'
                }}
                >
                  Ask AI Tutor
                </Box>
              </Button>
            </ButtonGroup>
            <Button
              onClick={handleNextQuestion}
              // rightIcon={currentQuestionIndex === questions.length - 1 && <ArrowRightIcon />}
              size={{ base: 'sm', lg: 'md' }}
              variant={currentQuestionIndex === questions.length - 1 ? 'primary' : 'solid'}
              colorScheme={'teal'}
              fontWeight={'bold'}
              isLoading={genQuestionsDataIsLoading}
              loadingText='Generating'
              // isDisabled={currentQuestionIndex === questions.length - 1}
              leftIcon={
                currentQuestionIndex === questions.length - 1 &&
                <Icon viewBox="0 0 24 24" boxSize={5}>
                  <path
                    fill="currentColor"
                    d="M7.5,5.6L5,7L6.4,4.5L5,2L7.5,3.4L10,2L8.6,4.5L10,7L7.5,5.6M19.5,15.4L22,14L20.6,16.5L22,19L19.5,17.6L17,19L18.4,16.5L17,14L19.5,15.4M22,2L20.6,4.5L22,7L19.5,5.6L17,7L18.4,4.5L17,2L19.5,3.4L22,2M13.34,12.78L15.78,10.34L13.66,8.22L11.22,10.66L13.34,12.78M14.37,7.29L16.71,9.63C17.1,10 17.1,10.65 16.71,11.04L5.04,22.71C4.65,23.1 4,23.1 3.63,22.71L1.29,20.37C0.9,20 0.9,19.35 1.29,18.96L12.96,7.29C13.35,6.9 14,6.9 14.37,7.29Z"
                  />
                </Icon>
              }
            >
              <Box display={{
                base: 'block',
                // md: 'block'
              }}
              >
                {currentQuestionIndex === questions.length - 1 ? 'Generate' : 'Next'}
              </Box>
            </Button>
          </Box>
        </VStack>
      </Container>

      {/* Chat Drawer component */}
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        onClose={() => {
          onClose();
          setIsChatOpen(false);
        }}
        finalFocusRef={chatBoxRef}
        size={'full'}
        closeOnOverlayClick={false}
      >
        {/* BeeBot section */}
        <DrawerOverlay bg={'white'} />
        <DrawerContent
        >
          <DrawerCloseButton
            size={{ base: 'md', lg: 'lg', xl: 'xl' }}
            bg={'teal'}
            colorScheme="teal"
            textColor={'white'}
            variant="solid"
            p={6}
            rounded={'full'}
            position={'absolute'}
            top={14}
            right={14}
            _hover={{ bg: 'teal.600' }}
            _active={{ bg: 'teal.700' }}
            fontSize={{ base: 'md', lg: '3xl', xl: '3xl' }}
            boxShadow="xl"
            zIndex={100}
          />

          <DrawerBody>
            <BeeBot threadId={threadId} helpRequest={JSON.stringify({
              helpRequest: 'Help me with the understanding the question, the concepts, the options, and the right answer. Give me a step by step and structured help',
              question: questions[currentQuestionIndex],
            })} />
          </DrawerBody>

        </DrawerContent>
      </Drawer>

      {/* Test Summary modal component */}
      <Modal isOpen={showResults} onClose={() => setShowResults(false)} size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <TestSummary results={calculateResults()} selectedAnswers={selectedAnswers} questions={questions} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setShowResults(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Fade >
  );
};

export default PracticeTest;
