const ModelDisplay = ({ displayText, displayBadge, displayBadgeStyle, className='' }) => {
  if (displayText || displayBadge || displayBadgeStyle) {
    return (
      <div className={className}>
        {displayText}
        <span className={displayBadgeStyle}>
          {displayBadge}
        </span>
      </div>
    );
  }
}

export default ModelDisplay;