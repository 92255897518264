import React, { ChangeEvent, Dispatch, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { Button, Box, Image, Text, VStack, Spinner, Input, IconButton, HStack, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { MdOutlineAttachFile, MdOutlineInsertPhoto } from 'react-icons/md';
import { CloseIcon } from '@chakra-ui/icons';
import { FileAndImageUrl } from '../../types/types';
import { useSelector } from 'react-redux';
import { selectBeeBotModel } from '../../../../store/store';

// Task 1: add session ID to the upload path
// Task 2: Make S3 files transient and delete after 7 days
// Task 3: Restrict S3 file type of images only

interface Props {
    fileUrls?: FileAndImageUrl[];
    setFileUrls: Dispatch<React.SetStateAction<FileAndImageUrl[]>>;
    // Add accepted file types prop
    acceptedFileTypes?: string;
}

const acceptedFileTypesList = '.c,.cpp,.cs,.css,.doc,.docx,.go,.html,.java,.js,.json,.md,.pdf,.php,.pptx,.py,.rb,.sh,.tex,.ts,.txt,text/x-c,text/x-c++,text/x-csharp,text/css,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/x-golang,text/html,text/x-java,text/javascript,application/json,text/markdown,application/pdf,text/x-php,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/x-python,text/x-script.python,text/x-ruby,application/x-sh,text/x-tex,application/typescript,text/plain';


export interface FileUploadButtonComponentRef {
    uploadFiles: (files: FileList) => void;
}

const FileUploadButton = forwardRef<FileUploadButtonComponentRef, Props>(({ fileUrls = [], setFileUrls, acceptedFileTypes= acceptedFileTypesList }: Props, ref) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Model
    const model = useSelector(selectBeeBotModel);

    const bgColorIcon = useColorModeValue(
        '#f1f5f9',
        '#1e293b'
    )

    useImperativeHandle(ref, () => {
        return {
            // ... your methods ...
            uploadFiles,
        };
    }, []);


    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;
        // console.log('files', files);
        // console.log('changeEvent', event);

        await uploadFiles(files);

    }

    const uploadFiles = async (files: FileList) => {
        if (!files) return;

        setLoading(true);
        setError(null);

        try {
            // Get the current authenticated user
            const user = await Auth.currentAuthenticatedUser();
            const userId = user.username;

            const uploadPromises = Array.from(files).map(async (file) => {
                // Create a unique key for the file based on user ID
                const fileKey = `${userId}/${file.name}`;

                // Upload the file to S3 with private access
                await Storage.put(fileKey, file, {
                    contentType: file.type,
                    level: 'private',
                });

                // Get a public URL for the uploaded file valid for 10 mins
                const publicUrl = await Storage.get(fileKey, {
                    level: 'private',
                    expires: 60 * 10, // 10 mins
                });

                return { url: publicUrl, key: fileKey };
            });

            const urls = await Promise.all(uploadPromises);
            setFileUrls(prev => ([...prev, ...urls]));
            console.log('Files uploaded successfully:', urls);
        } catch (error) {
            console.error('Error uploading files:', error);
            setError('Error uploading files. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // const handleDelete = useCallback(async (key: string) => {
    //     try {
    //         // Delete the file from S3
    //         await Storage.remove(key, { level: 'private' });

    //         // Remove the image from the state
    //         setImageUrls((prevUrls) => prevUrls.filter((image) => image.key !== key));
    //         console.log('File deleted successfully:', key);
    //     } catch (error) {
    //         console.error('Error deleting file:', error);
    //         setError('Error deleting file. Please try again.');
    //     }
    // }, []);

    return (
        <div>
            {/* <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            /> */}
            <Input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                multiple
                display="none"
                accept={acceptedFileTypes}
            />
            {/* <button
                onClick={() => document.getElementById('fileInput').click()}
                disabled={loading}
            >
                {loading ? 'Uploading...' : 'Upload Image'}
            </button> */}
            <Tooltip
                label={model?.acceptFiles ? 'Upload files' : `${model?.name} model does not accept files`}
                placement="right"
                hasArrow
                bg="gray.700"
                color="white"
                borderRadius="md"
                px="3"
                py="2"
            >

                <Button
                    id={'fileUploadButton.tsx'}
                    aria-label="Insert file"
                    w={'fit'}
                    variant="secondary"
                    bg={bgColorIcon}
                    onClick={(e) => {
                        if (loading || !model?.acceptFiles) {
                            e.preventDefault();
                            return;
                        }
                        document.getElementById('fileInput')?.click()
                    }}
                    disabled={loading || !model?.acceptFiles}
                    opacity={loading || !model?.acceptFiles ? 0.5 : 1}
                    cursor={loading || !model?.acceptFiles ? 'not-allowed' : 'pointer'}
                >
                    {loading
                        ? <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xs'
                        />
                        : <MdOutlineAttachFile className="text-xl" />}
                </Button>
            </Tooltip>

            {/* 
            {imageUrls.length > 0 && (
                <Box mt={2}>
                    <Text>Images uploaded successfully. Public URLs (valid for 1 hour):</Text>
                <HStack spacing={4} mt={2}>
                    {imageUrls.map((image, index) => (
                        <Box key={index} position="relative">
                            <a href={image.url} target="_blank" rel="noopener noreferrer">
                                {image.url}
                            </a>
                            <Image
                                src={image.url}
                                alt={`Uploaded Thumbnail ${index + 1}`}
                                boxSize="100px"
                                objectFit="cover"
                                borderRadius="md"
                                mt={2}
                            />
                            <IconButton
                                aria-label="Delete image"
                                icon={<CloseIcon />}
                                size="sm"
                                position="absolute"
                                top="3"
                                right="1"
                                onClick={() => handleDelete(image.key)}
                            />
                        </Box>
                    ))}
                </HStack>
            </Box>
            )
        }
        { error && <p style={{ color: 'red' }}>{error}</p> }
            */}
        </div >
    );
});

export default FileUploadButton;